<template>
  <div
    class="parcel-tracking-item"
    :class="{ 'is-extended': isExtended, isFirstStep: isFirstStep }"
  >
    <div class="parcel-tracking-item__header" @click="toggleVisible">
      <span
        >{{ title || "Title example" }}
        <b v-if="subtitle">{{ subtitle }}</b></span
      >
      <div class="parcel-tracking-item__header-icon">
        <img src="@/assets/img/parcel/ArrRight.svg" />
      </div>
    </div>
    <transition-expand>
      <div v-if="isExtended">
        <div class="parcel-tracking-item__content">
          <div
            class="parcel-tracking__step"
            v-for="(item, i) in list"
            :key="i"
            :class="{
              isActive: checkIsActive(item, i),
              isDisable: item.isOptional && !item.isActive,
            }"
          >
            <div class="parcel-tracking__step-name">
              {{ item.currentStatus }}
            </div>
            <div
              class="parcel-tracking__step-location"
              v-html="item.publicPosition"
            ></div>
            <div class="parcel-tracking__step-date">
              {{ formattedDate(item.eventTime) }}
            </div>
          </div>
        </div>
      </div>
    </transition-expand>
  </div>
</template>

<script>
import TransitionExpand from "@/components/TransitionExpand.vue";
export default {
  name: "ParcelTrackingItem",
  components: { TransitionExpand },
  props: {
    title: String,
    subtitle: String,
    list: Array,
    isOpened: Boolean,
    isActivated: Boolean,
    isFirstStep: Boolean,
  },
  data() {
    return {
      isExtended: this.isOpened || false,
    };
  },
  methods: {
    toggleVisible() {
      this.isExtended = !this.isExtended;
    },
    checkIsActive(item, i) {
      const isActive = this.list.slice(i).some((i) => i.isActive);
      return item.isActive || isActive || this.isActivated;
    },
    formattedDate(time) {
      return this.$moment.utc(time).local().format("DD MMMM YYYY, HH:mm");
    },
  },
  watch: {
    isOpened(newValue) {
      this.isExtended = newValue;
    },
  },
};
</script>

<style>
.parcel-tracking-item {
  border-bottom: 1px solid #f1f1f1;
  margin-top: 12px;
}
.parcel-tracking-item__header {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}
.parcel-tracking-item__header span {
  font-size: 16px;
  color: #282828;
  flex: 1 auto;
  font-family: "Helvetica Neue Medium";
}
.parcel-tracking-item__header span b {
  color: #91c258;
  font-weight: 400;
}
.parcel-tracking-item__header-icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 60px;
  transition: 0.3s;
}
.parcel-tracking-item__header-icon img {
  transform: rotate(90deg);
  transition: 0.2s;
}
.is-extended .parcel-tracking-item__header-icon img {
  transform: rotate(-90deg);
}
.parcel-tracking-item__content {
  padding-bottom: 16px;
  padding-top: 12px;
}
.parcel-tracking__step {
  padding-left: 28px;
  position: relative;
  padding-bottom: 33px;
  margin-top: -20px;
  box-sizing: border-box;
  min-height: 82px;
}
.parcel-tracking__step.isDisable {
  display: none;
}
.parcel-tracking__step:after {
  width: 8px;
  height: 8px;
  background: #ffffff;
  position: absolute;
  display: block;
  content: "";
  left: 4px;
  top: 2px;
  border-radius: 50%;
  z-index: 2;
}
.parcel-tracking__step:before {
  width: 12px;
  height: 99%;
  min-height: 102px;
  background: #f1f1f1;
  position: absolute;
  display: block;
  content: "";
  left: 2px;
  bottom: calc(100% - 12px);
  border-radius: 60px;
  z-index: -1;
}
.parcel-tracking__step:last-child {
  padding-bottom: 0px;
  min-height: 0px;
}
.parcel-tracking-item:last-child .parcel-tracking__step:last-child:before {
  height: 99%;
  bottom: calc(100% - 12px);
}
.parcel-tracking__step:nth-child(2):before {
  min-height: 58px;
}
.isFirstStep .parcel-tracking__step:nth-child(2):before {
  bottom: calc(100% - 19px);
}
.parcel-tracking__step:last-child:before {
  height: 130%;
  bottom: calc(70% - 12px);
}
.parcel-tracking__step:first-child {
  margin-top: 10px;
}
.parcel-tracking__step:first-child::before {
  height: 12px;
  min-height: auto;
}
.isActive.parcel-tracking__step:before {
  background: #91c258;
  z-index: 1;
}
.parcel-tracking__step-name {
  font-size: 14px;
  color: #282828;
  line-height: 17px;
  margin-bottom: 4px;
}
.parcel-tracking__step-location {
  text-transform: uppercase;
  color: #696969;
  font-size: 12px;
  line-height: 12px;
}
.parcel-tracking__step-date {
  font-size: 12px;
  color: #bfbfbf;
  line-height: 12px;
  margin-top: 4px;
  text-transform: capitalize;
}
</style>