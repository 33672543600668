<template>
  <div>
    <easy-bottom-sheet
      :isOpened="isOpened"
      :onHide="onClose"
      :title="
        product.name ? $t('cardEditProductTitle') : $t('cardAddProductTitle')
      "
    >
      <easy-input
        v-model="newName"
        :label="$t('addDeliveryOrderName')"
        :errorText="$t('addDeliveryNameErr')"
        :isError="isSubmitted && !newName"
      ></easy-input>

      <div class="easy-input__group">
        <easy-input
          :mask="customTokens"
          v-model.number="newPrice"
          :label="$t('cardEditPriceTitle')"
          :errorText="$t('addDeliveryPriceErr')"
          :isError="isSubmitted && !newPrice"
        />
        <easy-input
          :mask="customTokens"
          v-model.number="newCount"
          :label="$t('addDeliveryOrderCount')"
          :errorText="$t('addDeliveryPriceErr')"
          :isError="isSubmitted && !newCount"
        />
      </div>
      <template v-slot:footer>
        <button
          @click="onEdit"
          :class="{
            btnWideRound: true,
            disabled: !isValid,
          }"
        >
          {{ $t("btnSave") }}
        </button>
      </template>
    </easy-bottom-sheet>
  </div>
</template>

<script>
import EasyInput from "@/components/common/EasyInput.vue";
import EasyBottomSheet from "@/components/common/EasyBottomSheet.vue";

export default {
  name: "ParcelEditProduct",
  props: {
    product: Object,
    isOpened: {
      type: Boolean,
      default: false,
    },
    onSubmit: {
      type: Function,
      default: () => true,
    },
  },
  components: {
    EasyInput,
    EasyBottomSheet,
  },
  data() {
    return {
      newName: this.product.name || "",
      newPrice: this.product.price || "",
      newCount: this.product.count || "",
      isSubmitted: false,
      isLoading: false,

      customTokens: {
        mask: "NNNNN",
        tokens: {
          N: { pattern: /^[0-9]*\.?[0-9]*$/ },
        },
      },
    };
  },
  computed: {
    isValid() {
      return (
        !!this.newName &&
        !!this.newPrice &&
        !!this.newCount &&
        (this.newName !== this.product.name ||
          this.newPrice !== this.product.price ||
          this.newCount !== this.product.count)
      );
    },
  },
  methods: {
    onClose() {
      this.$emit("update:isOpened", false);
      this.isSubmitted = false;
      this.newName = this.product.name;
      this.newPrice = this.product.price;
      this.newCount = this.product.count;
    },
    onEdit() {
      this.isSubmitted = true;
      if (!this.isValid) return false;

      const newData = {
        ...this.product,
        name: this.newName,
        price: this.newPrice,
        count: this.newCount,
      };

      this.onSubmit(newData);

      this.$emit("update:isOpened", false);
      this.isSubmitted = false;
    },
  },
  watch: {
    product(newProduct) {
      this.newName = newProduct.name;
      this.newPrice = newProduct.price;
      this.newCount = newProduct.count;
    },
  },
};
</script>

<style></style>
