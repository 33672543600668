<template>
  <div class="parcel-address">
    <div class="parcel-address__header parcel-item__header">
      <span>{{ $t("cardAddressCustomerName") }}</span>
    </div>
    <div
      key="empty-address"
      v-if="isEditable && (isListEmpty || !selectedAddress.pr229)"
      class="parcel-address__content"
      @click="onOpen"
    >
      <div class="parcel-address__icon">
        <img src="@/assets/img/parcel/MyAddres.svg" />
      </div>
      <div class="parcel-address__info is-empty">
        {{ $t("cardAddressSelectCustomer") }}
      </div>
      <div class="parcel-address__action">
        <img src="@/assets/img/parcel/ArrDown.svg" />
      </div>
    </div>
    <div v-if="!isListEmpty">
      <div
        key="selected-address-np"
        v-if="currentAddress && !!selectedAddress.pr229"
        class="parcel-address__content"
        @click="onOpen"
      >
        <div class="parcel-address__icon">
          <img
            v-if="selectedAddress.pr229 === 11028"
            src="@/assets/img/Choosing/NP.svg"
          />
          <img
            v-if="selectedAddress.pr229 === 11212"
            src="@/assets/img/parcel/MyAddres.svg"
          />
        </div>
        <div class="parcel-address__info">
          <div class="parcel-address__info-user">
            <div class="parcel-address__info-name">
              {{ selectedAddressName }}
            </div>
            <div class="parcel-address__info-customer">
              {{ selectedCustomerName }}
            </div>
            <div class="parcel-address__info-phone">
              {{ selectedAddress.s_11009 }}
            </div>
          </div>
          <div class="parcel-address__info-location">
            <span v-if="localId">ТНН {{ localId }}</span>
            {{ selectedAddressLocation }}
          </div>
        </div>
        <div v-if="isEditable" class="parcel-address__action">
          <img src="@/assets/img/parcel/ArrDown.svg" />
        </div>
      </div>
    </div>

    <div
      key="selected-address-empty"
      v-if="!selectedAddress.pr229 && !isEditable"
      class="parcel-address__content"
    >
      <div class="parcel-address__icon">
        <img src="@/assets/img/parcel/MyAddres.svg" />
      </div>
      <div class="parcel-address__info">
        <div class="parcel-address__info-user">
          <div class="parcel-address__info-customer">
            {{ customerName }}
          </div>
        </div>
      </div>
    </div>

    <easy-bottom-sheet
      :isOpened.sync="isOpened"
      :title="$t('selectAddressTitle')"
    >
      <div class="address-list">
        <div
          class="address-list__item"
          v-for="(address, i) in addressList"
          :key="`${address.ID}${i}`"
          @click="selectAddress(address.ID)"
        >
          <div
            class="address-list__checkbox"
            :class="{ isActive: address.ID === currentAddress }"
          >
            <img src="@/assets/img/i-success.svg" />
          </div>
          <div class="address-list__user">
            <div class="address-list__label">{{ address.pr_222 }}</div>
            <div class="address-list__name">
              {{ address.pr_471_Name }} {{ address.pr_225 }}
            </div>
          </div>
          <div v-if="address.pr229 === 11028" class="address-list__info">
            <div class="address-list__type">
              <img src="@/assets/img/Choosing/NP.svg" />
            </div>
            <div class="address-list__location">{{ address.pr363_Name }}</div>
          </div>
          <div v-if="address.pr229 === 11212" class="address-list__info">
            <div class="address-list__type">
              {{ $t("cardAddressLocationDelivery") }}
            </div>
            <div class="address-list__location">
              {{ address.pr364_Name }}, {{ address.pr233_Name }},
              {{ address.pr234 }}
            </div>
          </div>
        </div>
      </div>
      <div class="address-list__new" @click="createNewAddress">
        <img src="@/assets/img/parcel/Add.svg" />
        <span>{{ $t("cardAddressAddMore") }}</span>
      </div>
    </easy-bottom-sheet>
  </div>
</template>

<script>
// Example address
// ID: 2685
// pr202: 432
// pr202_Name: "Київська"
// pr229: 11028
// pr233: 580222
// pr233_Name: "10-а Садова"
// pr234: "34"
// pr235: null
// pr363: 1621282
// pr363_Name: "Відділення №104 (до 30 кг): вул. Братиславська, 14б"
// pr364: 28879
// pr364_En: "Kyiv"
// pr364_Name: "Київ"
// pr375: null
// pr_222: "Name adress"
// pr_225: "Lastname"
// pr_256: "32432432"
// pr_298: false
// pr_389: null
// pr_471: 2914643
// pr_471_Name: "Firstname"

import { mapActions, mapGetters } from "vuex";

import EasyBottomSheet from "@/components/common/EasyBottomSheet.vue";

export default {
  name: "ParcelAddress",
  props: {
    currentAddress: Number,
    customerName: String,
    onSelect: {
      type: Function,
      default: () => true,
    },
    isEditable: Boolean,
    localId: String,
  },
  components: {
    EasyBottomSheet,
  },
  data() {
    return {
      isOpened: false,
    };
  },
  computed: {
    ...mapGetters("user", ["userId"]),
    ...mapGetters("address", ["addressList", "defaultAddress"]),
    selectedAddress() {
      if (this.isListEmpty) return {};
      return this.addressList.find((el) => el.ID === this.currentAddress) || {};
    },
    isListEmpty() {
      return !this.addressList.length;
    },
    selectedAddressName() {
      const { pr_222: addressName } = this.selectedAddress;
      return addressName;
    },
    selectedCustomerName() {
      const { pr_471_Name: firstName, pr_225: lastName } = this.selectedAddress;
      return `${firstName} ${lastName}`;
    },
    selectedAddressLocation() {
      const {
        pr375: moreInfo,
        pr229: type,
        pr364_Name: cityName = "",
        pr363_Name: npAddress = "",
        pr233_Name: streetName = "",
        pr234: buildNumber = "",
        pr_630: stateName = "",
        pr_256: postCode = "",
      } = this.selectedAddress;
      const isNP = type === 11028;
      const isLocal = type === 11212;

      if (isNP) return `${cityName ? cityName + "," : ""} ${npAddress}`;

      if (isLocal) {
        const addressList = [
          moreInfo,
          streetName,
          buildNumber,
          cityName,
          stateName,
          postCode,
        ].filter(Boolean);

        return addressList.join(", ");
      }

      return "";
    },
  },
  async created() {
    await this.getAddressList();
    if (!this.isListEmpty && !this.currentAddress && this.isEditable) {
      this.selectAddress(this.defaultAddress.ID);
    }
  },
  methods: {
    ...mapActions("address", ["getAddressList"]),
    createNewAddress() {
      this.$router.push("/address-list/new");
    },
    selectAddress(id) {
      this.onSelect(id);
      this.isOpened = false;
    },
    onOpen() {
      if (!this.isEditable) return false;
      this.isOpened = true;
    },
  },
};
</script>

<style>
.parcel-address:last-child {
  border-bottom: 1px solid #f1f1f1;
}
.parcel-item__header {
  margin-left: -16px;
  margin-right: -16px;
  background: #f8f8f8;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 12px;
  padding-top: 24px;
  color: #282828;
  font-size: 16px;
  font-family: "Helvetica Neue Medium";
  display: flex;
  justify-content: space-between;
}
.parcel-address__content {
  display: flex;
  align-items: center;
  min-height: 60px;
}
.parcel-address__icon {
  min-width: 24px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.parcel-address__action {
  min-width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.parcel-address__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 auto;
  padding-top: 6px;
  padding-bottom: 6px;
}
.parcel-address__info-user {
  font-size: 16px;
  color: #282828;
  padding-left: 16px;
  max-width: 50%;
}
.parcel-address__info-name {
  font-size: 16px;
  line-height: 1.3;
}
.parcel-address__info-customer {
  font-size: 12px;
  line-height: 1.3;
  color: #696969;
}
.parcel-address__info-phone {
  font-size: 12px;
  line-height: 1.3;
  color: #696969;
}
.parcel-address__info-location {
  font-size: 12px;
  color: #696969;
  line-height: 14px;
  max-width: 50%;
  text-align: right;
}
.parcel-address__info-location span {
  font-size: 14px;
  padding-bottom: 4px;
  padding-top: 2px;
  display: block;
}
.parcel-address__info.is-empty {
  color: #f95757;
  padding-left: 15px;
}

.address-list__item {
  margin-left: 40px;
  border-bottom: 1px solid #f1f1f1;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  position: relative;
  line-height: 1.3;
}
.address-list__checkbox {
  border: 2px solid #bfbfbf;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  position: absolute;
  left: -40px;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.address-list__checkbox.isActive {
  background: #91c258;
  border: 2px solid#91C258;
}
.address-list__user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
}
.address-list__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  text-align: right;
}
.address-list__label {
  font-size: 16px;
  color: #282828;
  margin-bottom: 4px;
}
.address-list__name {
  color: #696969;
  font-size: 12px;
}
.address-list__type {
  color: #282828;
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 4px;
}
.address-list__location {
  color: #bfbfbf;
  font-size: 12px;
  line-height: 1.4;
}
.address-list__new {
  display: flex;
  align-items: center;
  min-height: 64px;
  padding-top: 12px;
  padding-bottom: 10px;
  box-sizing: border-box;
  position: relative;
  padding-left: 40px;
}
.address-list__new img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 4px;
}
</style>
