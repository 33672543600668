<template>
  <div class="parcel-gallery">
    <div v-if="!isImagesLoaded" class="parcel-gallery-loader"></div>
    <div v-if="!photoList.length" class="parcel-gallery__empty">
      <img
        src="@/assets/img/parcel-empty.svg"
        class="parcel-gallery__empty-img"
      />
      <div class="parcel-gallery__empty-title">
        {{ $t("parcelEmptyPhotoText") }}
      </div>
    </div>

    <div v-if="photoList.length" class="parcel-gallery__slider">
      <swiper
        class="swiper parcel-gallery__images"
        :options="swiperOptions"
        @click-slide="sliderClicked"
      >
        <swiper-slide v-for="(imgSrc, i) in photoList" :key="imgSrc + i">
          <div class="parcel-gallery__img">
            <img :src="imgSrc" />
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-pagination"></div>
    </div>

    <parcel-gallery-more
      v-scroll-lock="isScrollLocked"
      v-if="isGalleryOpened"
      :onClose="onMoreClose"
      :imageList="photoList"
      :activeIndex="activeIndex"
    />

    <div
      v-if="!isNewPhotoChecked && isReadyToDelivery"
      @click="onCheckNewPhoto"
      class="parcel-gallery_new-photo"
    >
      <span>{{ $t("newPhotoTitle") }}</span>
      <div id="radar">
        <div class="wave wave1"></div>
        <div class="wave wave2"></div>
        <div class="wave wave3"></div>
        <img src="@/assets/img/parcel/Photo.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import ParcelGalleryMore from "./ParcelGalleryMore.vue";
import { getParcelImages } from "@/services/requests-list";

export default {
  name: "ParcelGallery",
  components: {
    Swiper,
    SwiperSlide,
    ParcelGalleryMore,
  },
  props: {
    // imagesList: Array,
    additionalImg: String,
    isReadyToDelivery: Boolean,
    parcelId: Number,
  },
  data() {
    return {
      isGalleryOpened: false,
      swiperOptions: {
        slidesPerView: 1,
        pagination: {
          el: ".swiper-pagination",
        },
      },
      imagesList: [],
      isImagesLoaded: false,
      activeIndex: 0,
      isNewPhotoChecked:
        window.localStorage.getItem(`isNewPhotoChecked${this.parcelId}`) || "",
    };
  },
  async mounted() {
    await this.getImages();
    this.isImagesLoaded = true;
  },
  computed: {
    isScrollLocked() {
      if (document.body.classList.contains("web")) return false;
      return this.isGalleryOpened;
    },
    photoList() {
      return [
        ...new Set([this.additionalImg, ...this.imagesList].filter(Boolean)),
      ];
    },
  },
  methods: {
    sliderClicked(realIndex) {
      this.activeIndex = realIndex;
      this.isGalleryOpened = true;
    },
    newPhotoClicked() {
      this.onCheckNewPhoto();
      this.isGalleryOpened = true;
    },
    onMoreClose() {
      this.isGalleryOpened = false;
    },
    onCheckNewPhoto() {
      window.localStorage.setItem(`isNewPhotoChecked${this.parcelId}`, "true");
      this.isNewPhotoChecked = "true";
      this.isGalleryOpened = true;
    },
    async getImages() {
      this.imagesList = await getParcelImages(this.parcelId).catch(
        (res) => res
      );
    },
  },
};
</script>

<style>
.parcel-gallery {
  background: #f7f5f8;
  height: 240px;
  position: relative;
}
.ios .parcel-gallery {
  height: 300px;
  margin-top: -60px;
}
.parcel-gallery__empty-img {
  display: block;
  margin-bottom: 16px;
  max-height: 130px;
}
.parcel-gallery__empty-title {
  font-size: 12px;
  line-height: 1.3;
  color: #bfbfbf;
  text-align: center;
  max-width: 280px;
  margin: 0 auto;
}
.parcel-gallery__slider {
  max-height: 100%;
  height: 100%;
  position: relative;
}
.parcel-gallery__img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 240px;
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.ios .parcel-gallery__img {
  height: 300px;
}
.parcel-gallery__img img {
  display: block;
  max-width: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  z-index: -1;
}
div.parcel-gallery__slider .swiper-pagination-bullet {
  background: #333333;
  transition: background 0.2s;
  width: 6px;
  height: 6px;
  margin: 4px;
  opacity: 1;
}
div.parcel-gallery__slider .swiper-pagination-bullet-active {
  background: #efb443;
}
div.parcel-gallery__slider .swiper-pagination {
  margin: auto;
  left: 0;
  right: 0;
  bottom: 18px;
  z-index: 2;
}
.parcel-gallery_new-photo {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  flex-direction: column;
}
.parcel-gallery_new-photo span {
  text-align: center;
  font-size: 14px;
  color: #fff;
  max-width: 250px;
  line-height: 1.4;
}

#radar {
  width: 70px;
  height: 70px;
  position: relative;
}
#radar img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
#radar .wave {
  width: 0;
  height: 0;
  margin: 0 auto;
  position: relative;
  border-radius: 50%;
  border: 2px solid #efb443;
  opacity: 0;
  animation-name: radar;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-play-state: running;
  animation-duration: 1.4s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.6, 0.13, 0.79, 0.49);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#radar .wave1 {
  animation-delay: 0.3s;
}
#radar .wave2 {
  animation-delay: 0.6s;
}
#radar .wave3 {
  animation-delay: 0.8s;
}

@keyframes radar {
  0% {
    opacity: 0;
    width: 0;
    height: 0;
  }

  50% {
    opacity: 1;
    width: 20px;
    height: 20px;
  }

  100% {
    opacity: 0;
    width: 70px;
    height: 70px;
    border-width: 1;
  }
}

.parcel-gallery-loader {
  width: 100%;
  height: 100%;
  background-color: whitesmoke;
  position: relative;
  overflow: hidden;
}
.parcel-gallery-loader:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 1);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: animationLoader 1.2s cubic-bezier(0.645, 0.045, 0.355, 1) both
    infinite;
}

@keyframes animationLoader {
  from {
    left: -100%;
  }
  to {
    left: 100%;
  }
}
</style>