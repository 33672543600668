<template>
  <div class="parcel-product-list__container">
    <parcel-product-item
      :product="productList[0]"
      :onEdit="() => onEdit(0)"
      :isEditable="isEditable"
    />
    <div
      class="parcel-product-list"
      v-if="productList.length > 1"
      :style="{
        'max-height': `${isExpanded ? productList.length * 100 : 0}px`,
      }"
    >
      <parcel-product-item
        v-for="(item, i) in restProducts"
        :key="i"
        :product="item"
        :onEdit="() => onEdit(i + 1)"
        :isEditable="isEditable"
      />
    </div>
    <button
      v-if="productList.length > 1 && !isExpanded"
      @click="isExpanded = true"
      class="btnWideRound"
    >
      {{
        `${$t("cardProductListMoreBtn")} ${productList.length - 1} ${$t(
          "productParcelPu"
        ).toLowerCase()}`
      }}
    </button>
    <div v-if="productList.length > 1" class="parcel-product-list__total">
      {{ $t("cardProductListAll") }}: <b>${{ totalPrice }}</b>
    </div>
  </div>
</template>

<script>
import ParcelProductItem from "./ParcelProductItem.vue";

export default {
  name: "ParcelProductList",
  components: { ParcelProductItem },
  props: {
    productList: Array,
    onEdit: {
      type: Function,
      default: () => true,
    },
    totalPrice: Number,
    isEditable: Boolean,
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    restProducts() {
      const [, ...rest] = this.productList;
      return rest;
    },
  },
};
</script>

<style>
.parcel-product-list__container .btnWideRound {
  background: rgba(145, 194, 88, 0.1);
  color: #91c258;
  margin-top: 10px;
  margin-bottom: 10px;
}
.parcel-product-list {
  overflow: hidden;
}
.parcel-product-list__total {
  font-size: 12px;
  color: #696969;
}
.parcel-product-list__total b {
  color: #282828;
}
</style>
