<template>
  <div class="parcel-new-products">
    <parcel-product-item
      v-for="(item, i) in addedProducts"
      :key="i"
      :product="item"
      :onEdit="() => onEditProduct(i)"
      :onRemove="() => onRemove(i)"
      isEditable
      isRemovable
    />

    <button @click="onAdd" class="btnWideRound">
      {{ $t("btnAddProduct") }}
    </button>

    <parcel-edit-product
      :product="editProduct"
      :isOpened.sync="isEditProductOpened"
      :onSubmit="onSaveProduct"
    />
  </div>
</template>

<script>
import ParcelProductItem from "./ParcelProductItem.vue";
import ParcelEditProduct from "./ParcelEditProduct.vue";

export default {
  name: "ParcelNewProducts",
  components: { ParcelProductItem, ParcelEditProduct },
  props: {
    addedProducts: Array,
  },
  data() {
    return {
      parcelLocalId: `parcelAddedProducts${this.$route.params.parcelId}`,
      isEditProductOpened: false,
      editProduct: {},
    };
  },
  methods: {
    onSaveProduct(newData) {
      let list = [];
      if (!this.editProduct.name) {
        list = [
          ...this.addedProducts,
          {index: this.addedProducts.length, ...newData}
        ];
      } else {
        list = this.addedProducts.map((item, idx) =>
          idx === this.editProduct.index ? newData : item
        );
      }
      this.$emit("update:addedProducts", list);
      window.localStorage.setItem(this.parcelLocalId, JSON.stringify(list));
      this.editProduct = {};
    },
    onRemove(idx) {
      const list = this.addedProducts.filter((el, i) => i !== idx);
      this.$emit("update:addedProducts", list);
      window.localStorage.setItem(this.parcelLocalId, JSON.stringify(list));
    },
    onEditProduct(id) {
      this.editProduct = {
        index: id,
        ...this.addedProducts[id] || {},
      };
      this.isEditProductOpened = true;
    },
    onAdd() {
      this.editProduct = {};
      this.isEditProductOpened = true;
    }
  },
};
</script>

<style>
.parcel-new-products > .btnWideRound {
  background: rgba(145, 194, 88, 0.1);
  color: #91c258;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>