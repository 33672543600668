<template>
  <transition key="parcel-check" name="fade">
    <div v-if="isOpened" class="parcel-check">
      <div class="parcel-check__overlay" @click="onClose"></div>
      <div class="parcel-check__wrapper">
        <div class="parcel-check__content">
          <div class="parcel-check__close" @click="onClose">
            <img src="@/assets/img/close-w.svg" />
          </div>
          <div class="parcel-check__title">{{ $t("cardCheckName") }}</div>
          <div class="parcel-check__list">
            <parcel-check-item
              v-if="isBuyout"
              :name="$t('cardCheckProductPrice')"
              :value="pricesData.productPrice.Cost"
              isSuccess
            />
            <parcel-check-item
              v-if="isBuyout"
              :name="$t('cardCheckBuyoutService')"
              :value="pricesData.buyout.Cost"
              isSuccess
            />
            <parcel-check-item
              v-if="pricesData.innerDelivery.Cost"
              :name="$t('cardCheckInnerDelivery')"
              :value="pricesData.innerDelivery.Cost"
              isSuccess
            />
            <!-- <parcel-check-item
              v-if="!isBuyout"
              :name="$t('cardCheckProductPrice')"
              :value="totalProductsPrice"
              isSuccess
            /> -->
            <parcel-check-item
              v-if="pricesData.werehouse.Cost"
              :name="$t('cardCheckWerehouse')"
              :value="pricesData.werehouse.Cost"
              :isSuccess="isFullPaid"
            />
            <parcel-check-item
              v-if="pricesData.countryDelivery.Cost"
              :name="$t('cardCheckCountryDelivery')"
              :value="pricesData.countryDelivery.Cost"
              :isSuccess="isFullPaid"
            />
            <parcel-check-item
              v-if="pricesData.localDelivery.Cost"
              :name="$t('cardCheckLocalDelivery')"
              :value="pricesData.localDelivery.Cost"
              :isSuccess="isFullPaid"
            />
            <parcel-check-item
              v-if="pricesData.insurance.Cost"
              :name="$t('cardCheckInsurance')"
              :value="pricesData.insurance.Cost"
              :isSuccess="isFullPaid"
            />
            <parcel-check-item
              v-if="pricesData.combine.Cost"
              :name="
                $t('cardCheckCombine') +
                  ` <b>${
                    pricesData.combine.Base > 1
                      ? 'x' + pricesData.combine.Base
                      : ''
                  }</b>`
              "
              :value="pricesData.combine.Cost"
              :isSuccess="isFullPaid"
            />
            <parcel-check-item
              v-if="pricesData.divide.Cost"
              :name="
                $t('cardCheckDivide') +
                  ` <b>${
                    pricesData.divide.Base > 1
                      ? 'x' + pricesData.divide.Base
                      : ''
                  }</b>`
              "
              :value="pricesData.divide.Cost"
              :isSuccess="isFullPaid"
            />
            <parcel-check-item
              v-if="pricesData.additionalBox.Cost"
              :name="$t('cardCheckAdditionalBox')"
              :value="pricesData.additionalBox.Cost"
              :isSuccess="isFullPaid"
            />
            <parcel-check-item
              v-if="pricesData.additionalPhoto.Cost"
              :name="$t('cardCheckAdditionalPhoto')"
              :value="pricesData.additionalPhoto.Cost"
              :isSuccess="isFullPaid"
            />
            <parcel-check-item
              v-if="customsServiceResult"
              :name="$t('customsCostShPu')"
              :value="customsServiceResult"
              :isSuccess="isCustomsPaid"
            />
          </div>
          <div class="parcel-check__total">
            <span>{{ $t("cardCheckTotal") }}</span>
            <b
              >${{ +totalPrice
              }}<img
                v-if="customsServiceResult"
                src="@/assets/img/parcel/star-black.svg"
                class="img-star"
            /></b>
          </div>

          <div
            v-if="!isFullPaid || (!isCustomsPaid && customsServiceResult)"
            class="parcel-check__result"
          >
            <div class="parcel-check__result-item">
              <span>{{ $t("cardCheckPaid") }}</span>
              <b>${{ +totalPaid | numberRound }}</b>
            </div>
            <div class="parcel-check__result-item">
              <span>{{ $t("cardCheckDiff") }}</span>
              <b
                >${{ +(totalPrice - totalPaid) | numberRound
                }}<img src="@/assets/img/parcel/star-black.svg" class="img-star"
              /></b>
            </div>
          </div>
          <div class="parcel-check__helps" v-if="customsServiceResult">
            <img src="@/assets/img/parcel/star-gray.svg" class="img-star" />{{
              $t("cardCheckHelps")
            }}
          </div>
          <img src="@/assets/img/paper.svg" class="parcel-check__paper" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ParcelCheckItem from "./ParcelCheckItem.vue";

export default {
  name: "ParcelCheck",
  components: { ParcelCheckItem },
  props: {
    isOpened: Boolean,
    isBuyout: Boolean,
    isFullPaid: Boolean,
    totalProductsPrice: Number,
    totalPrice: Number,
    pricesData: Object,
    customsServiceResult: Number,
    isCustomsPaid: Boolean,
  },
  computed: {
    totalPaid() {
      const buyoutTotal =
        this.pricesData.innerDelivery.Cost ||
        0 + this.pricesData.productPrice.Cost ||
        0 + this.pricesData.buyout.Cost ||
        0;
      const parcelTotal =
        this.totalProductsPrice || 0 + this.pricesData.innerDelivery.Cost || 0;

      const firstPaidPrice = this.isBuyout ? buyoutTotal : parcelTotal;
      const secondPaidPrice = this.totalPrice - this.customsServiceResult;

      if (!this.isFullPaid) {
        return firstPaidPrice;
      } else if (!this.isCustomsPaid) {
        return secondPaidPrice;
      } else {
        return this.totalPrice;
      }
    },
  },
  methods: {
    onClose() {
      this.$emit("update:isOpened", false);
    },
  },
};
</script>

<style>
.parcel-check {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 600;
  box-sizing: border-box;
  padding-top: 50px;
  padding-bottom: 50px;
  background: rgb(40, 40, 40, 0.8);
}
.parcel-check__overlay {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.parcel-check__wrapper {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
  max-height: 100%;
  overflow: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
}
.parcel-check__content {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 12px;
  padding-top: 32px;
  background: #ffffff;
  position: relative;
  max-width: 340px;
  margin-left: auto;
  margin-right: auto;
}
.parcel-check__close {
  background: #efb443;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.2);
  border-radius: 32px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  position: absolute;
  right: 16px;
  top: -21px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.parcel-check__close img {
  display: block;
}
.parcel-check__paper {
  position: absolute;
  left: 0;
  bottom: -30px;
  z-index: -1;
  width: 100%;
}
.parcel-check .img-star {
  display: inline-block;
  width: 6px;
  position: relative;
  top: -6px;
  margin-left: 1px;
  margin-right: 1px;
}
.parcel-check__title {
  font-size: 16px;
  color: #282828;
  font-family: "Helvetica Neue Medium";
  margin-bottom: 10px;
}
.parcel-check__total {
  border-top: 1px dashed #bfbfbf;
  padding-top: 8px;
  margin-top: 8px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  font-family: "Roboto Mono";
}
.parcel-check__total span {
  color: #696969;
  font-size: 14px;
  font-weight: 500;
}
.parcel-check__total b {
  text-align: right;
  color: #282828;
  font-size: 14px;
  font-weight: 500;
}
.parcel-check__result-item {
  display: flex;
  justify-content: space-between;
  font-family: "Roboto Mono";
}
.parcel-check__result-item span {
  color: #696969;
  font-size: 14px;
  font-style: italic;
}
.parcel-check__result-item b {
  text-align: right;
  color: #282828;
  font-size: 14px;
  font-weight: 500;
}
.parcel-check__helps {
  color: #bfbfbf;
  font-size: 12px;
  line-height: 1.3;
  margin-top: 16px;
}
</style>
