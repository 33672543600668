import { render, staticRenderFns } from "./ParcelStatusItem.vue?vue&type=template&id=020e9f10&"
import script from "./ParcelStatusItem.vue?vue&type=script&lang=js&"
export * from "./ParcelStatusItem.vue?vue&type=script&lang=js&"
import style0 from "./ParcelStatusItem.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports