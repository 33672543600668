<template>
  <div class="parcel-status__container">
    <parcel-status-item
      key="status-divide"
      v-if="stateId === 5030"
      :statusText="$t('dividingPC')"
    />
    <parcel-status-item
      key="status-combine"
      v-else-if="stateId === 5034"
      :statusText="$t('combinePC')"
    />
    <parcel-status-item
      key="status-received"
      v-else-if="stateId === 5013 || stateId === -5003"
      :statusText="$t('receivedPC')"
    >
      <img v-if="arrivedDaysCount <= 10" src="@/assets/img/happy.svg" />
      <img v-if="arrivedDaysCount >= 20" src="@/assets/img/sad.svg" />
      <span>{{ arrivedDaysText }}</span>
    </parcel-status-item>
    <parcel-status-item
      key="status-expected-werehouse"
      v-else-if="shopStep === 1"
      :statusText="$t('checkShoppingDash')"
    />
    <parcel-status-item
      key="status-arrived-werehouse"
      v-else-if="shopStep === 2"
      :statusText="`${$t('needPayPC')} $${totalCost}`"
      type="isRed"
    />
    <parcel-status-item
      key="status-processed-werehouse"
      v-else-if="shopStep === 3 && deliveryStep !== 1"
      :statusText="$t('buyoutPC')"
      :helpText="$t('cardStatusHelpBuyout')"
    />
    <parcel-status-item
      key="status-expected-werehouse"
      v-else-if="deliveryStep === 1"
      :statusText="$t('expectedWarehousePC')"
      :helpText="$t('cardStatusHelpSended')"
    />
    <parcel-status-item
      key="status-arrived-werehouse-empty"
      v-else-if="stateId === 5017 && isProductListEmpty"
      :statusText="$t('arrivedWarehousePC')"
    />
    <parcel-status-item
      key="status-arrived-werehouse"
      v-else-if="deliveryStep === 21 && isProductListEmpty"
      :statusText="$t('arrivedWarehousePC')"
      :helpText="arrivedDate"
    />
    <parcel-status-item
      key="status-processed-werehouse"
      v-else-if="deliveryStep === 22"
      :statusText="$t('processedWarehousePC')"
      :helpText="$t('cardStatusHelpPayment')"
      helpType="isRed"
    />
    <parcel-status-item
      key="status-sending"
      v-else-if="deliveryStep === 3"
      :statusText="$t('sendingPC')"
      icon="plane"
    />
    <parcel-status-item
      key="status-delivery"
      v-else-if="deliveryStep === 4"
      :statusText="$t('deliveryPC')"
      icon="plane"
    />
    <parcel-status-item
      key="status-arrived-ukraine"
      v-else-if="deliveryStep === 51"
      :statusText="$t('arrivedUkrainePC')"
    />
    <parcel-status-item
      key="status-customs-check"
      v-else-if="deliveryStep === 52"
      :statusText="$t('customsCheckPC')"
      icon="search"
    />
    <parcel-status-item
      key="status-customs-form"
      v-else-if="deliveryStep === 53"
      type="isRed"
      :statusText="$t('customsFormPC')"
      icon="invoice"
    />
    <parcel-status-item
      key="status-moved-np"
      v-else-if="deliveryStep === 61"
      :statusText="$t('movedToNP_PC')"
    />
    <parcel-status-item
      key="status-moved-adress"
      v-else-if="deliveryStep === 62"
      :statusText="$t('movedToCarrierPC')"
    />
    <parcel-status-item
      key="status-arrived-final"
      v-else-if="deliveryStep === 63"
    >
      <img v-if="arrivedDaysCount <= 10" src="@/assets/img/happy.svg" />
      <img v-if="arrivedDaysCount >= 20" src="@/assets/img/sad.svg" />
      <span>{{ arrivedDaysText }}</span>
    </parcel-status-item>
  </div>
</template>

<script>
import { getNumEnding } from "@/utils/utils";
import ParcelStatusItem from "./ParcelStatusItem.vue";

export default {
  name: "ParcelStatus",
  components: { ParcelStatusItem },
  props: {
    shopStep: Number,
    deliveryStep: Number,
    stateId: Number,
    totalCost: Number,
    arrivedDate: String,
    arrivedDaysCount: Number,
    isProductListEmpty: Boolean,
  },
  computed: {
    arrivedDaysText() {
      return `${this.$t("getOnPC")} ${getNumEnding(this.arrivedDaysCount, [
        this.$t("dayPC"),
        this.$t("days3PC"),
        this.$t("daysPC"),
      ])}`;
    },
  },
};
</script>
