<template>
  <div class="parcel-status">
    <div class="parcel-status__value" :class="`${type ? type : ''}`">
      <img v-if="icon === 'plane'" src="@/assets/img/parcel/Airplane.svg" />
      <img v-if="icon === 'search'" src="@/assets/img/parcel/Search.svg" />
      <img v-if="icon === 'invoice'" src="@/assets/img/parcel/Invoice.svg" />
      <slot name="status-icon"></slot>
      <slot><span>{{ statusText }}</span></slot>
    </div>
    <div class="parcel-status__help" :class="`${helpType}`">
      <span>{{ helpText }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ParcelStatusItem",
  props: {
    type: String,
    helpType: String,
    statusText: String,
    helpText: String,
    icon: String,
  },
};
</script>

<style>
.parcel-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 42px;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 8px;
}
.parcel-status__help {
  color: #bfbfbf;
  font-size: 12px;
  line-height: 1.4;
  text-align: right;
  padding-top: 1px;
}
.parcel-status__value {
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Helvetica Neue Medium";
  display: flex;
  align-items: center;
  flex-shrink: 3;
  color: #91c258;
}
.parcel-status__value.isRed,
.parcel-status__help.isRed {
  color: #ef8143;
}
.parcel-status__value span {
  flex: 1 auto;
  line-height: 1.3;
  padding-top: 4px;
  display: block;
  height: 100%;
}
.parcel-status__value img {
  max-width: 20px;
  margin-right: 10px;
  display: block;
}
</style>