<template>
  <div class="parcel-tracking">
    <div class="parcel-tracking__header">
      <div class="parcel-tracking__back" @click="onClose">
        <img src="@/assets/img/arr-l-w.svg" />
      </div>
      <!-- {{ $t("cardTrackingTitle") }} -->
      {{ tracking }}
    </div>
    <div class="parcel-tracking__content">
      <div class="parcel-tracking__status">
        <div class="parcel-tracking__status-from">
          <img src="@/assets/img/parcel/China.svg" />
        </div>
        <div
          class="parcel-tracking__status-left"
          :class="{ isActive: currentStep >= 3 }"
        ></div>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="parcel-tracking__status-plane"
          :class="{ isActive: currentStep >= 3, isFinal: isDelivered }"
        >
          <g clip-path="url(#clip0)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.12556 10.1257L12.3642 10.1256L9.67924 2.74202C9.5808 2.47132 9.7342 2.25065 10.0224 2.25065L11.2287 2.25065C11.5098 2.25065 11.8769 2.44924 12.0328 2.6942L16.7619 10.1256L18.8756 10.1256C20.134 10.1277 22.3035 10.5536 22.2378 11.9879C22.1721 13.4222 20.1867 13.931 18.9285 13.9286L16.7334 13.9205L12.0328 21.3071C11.8769 21.5521 11.5098 21.7507 11.2287 21.7507L10.0224 21.7507C9.7342 21.7507 9.5808 21.53 9.67924 21.2593L12.3538 13.9043L6.12002 13.8812L4.62383 15.3774C4.41768 15.5835 4.04243 15.6597 3.76968 15.5405L3.39945 15.3788C3.13387 15.2627 3.01437 14.9408 3.1267 14.6737L4.25056 12.0007L2.98493 9.4694C2.85843 9.2164 2.96305 8.89441 3.22517 8.76335L3.77595 8.48796C4.04199 8.35493 4.41768 8.41777 4.62383 8.62392L6.12556 10.1257Z"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <div
          class="parcel-tracking__status-right"
          :class="{ isActive: isDelivered }"
        ></div>
        <div class="parcel-tracking__status-to">
          <img
            v-if="userHomeCountry === 285"
            src="@/assets/img/parcel/Ukraine.svg"
          />
          <img
            v-if="userHomeCountry === 284"
            src="@/assets/img/parcel/USA.svg"
          />
          <img
            v-if="userHomeCountry === 301"
            src="@/assets/img/parcel/UK.svg"
          />
          <img
            v-if="userHomeCountry === 308"
            src="@/assets/img/parcel/CA.svg"
          />
        </div>
      </div>
      <div class="parcel-tracking__list">
        <parcel-tracking-item
          v-if="buyoutId"
          :title="$t('cardTrackingBuyoutTitle')"
          :list="buyoutList"
          :isOpened="currentStep === 1"
          :isActivated="currentStep > 1"
          isFirstStep
        />
        <parcel-tracking-item
          :title="$t('cardTrackingWerehouseTitle')"
          :list="werehouseList"
          :isOpened="currentStep === 2"
          :isActivated="currentStep > 2"
          :isFirstStep="!buyoutId"
        />
        <parcel-tracking-item
          :title="$t('cardTrackingDeliveryTitle')"
          :subtitle="deliveryTime"
          :list="deliveryList"
          :isOpened="[3].includes(currentStep)"
        />
      </div>
      <div class="parcel-tracking__additional">
        <slot></slot>
      </div>
      <parcel-status-item key="status-arrived-final" v-if="isDelivered">
        <img v-if="arrivedDaysCount <= 10" src="@/assets/img/happy.svg" />
        <img v-if="arrivedDaysCount >= 20" src="@/assets/img/sad.svg" />
        <span>{{ arrivedDaysText }}</span>
      </parcel-status-item>
    </div>
  </div>
</template>

<script>
import {
  getTrackingStatus,
  getTrackingStatusList,
} from "@/services/requests-list";
import ParcelTrackingItem from "./ParcelTrackingItem.vue";
import ParcelStatusItem from "./ParcelStatusItem.vue";
import { getNumEnding } from "@/utils/utils";
import { mapGetters } from "vuex";

// const trackingStatusList = {
//   11525: "trackingStatusBuyoutCheck",
//   11526: "trackingStatusBuyoutPay",
//   11527: "trackingStatusBuyoutBuy",
//   11528: "trackingStatusProcessWait",
//   11461: "trackingStatusProcessArrive",
//   11462: "trackingStatusProcessDone",
//   11463: "trackingStatusDeliveryInclude",
//   11465: "trackingStatusDeliveryPreArrive",
//   11464: "trackingStatusDeliverySended",
//   11468: "trackingStatusDeliveryOnNP",
//   11673: "trackingStatusDeliveryFinal",
// };

const updateTrackingList = (oldList, newList) => {
  return oldList
    .map((item) => {
      const sameStatus = newList.filter(({ typeEvent_id }) =>
        item.typeEvent_id.includes(typeEvent_id)
      );
      if (!sameStatus.length) return item;
      return sameStatus.map((sameItm) => ({
        ...sameItm,
        ...item,
        isActive: true,
      }));
    })
    .flat();
};

export default {
  name: "ParcelTracking",
  components: { ParcelTrackingItem, ParcelStatusItem },
  props: {
    country: String,
    tracking: String,
    buyoutId: Number,
    deliveryStep: Number,
    stateId: Number,
    arrivedDaysCount: Number,
    onClose: {
      type: Function,
      default: () => true,
    },
  },
  data() {
    return {
      buyoutList: [
        {
          currentStatus: this.$t("trackingStatusBuyoutCheck"),
          typeEvent_id: 11525,
        },
        {
          currentStatus: this.$t("trackingStatusBuyoutPay"),
          typeEvent_id: 11526,
        },
        {
          currentStatus: this.$t("trackingStatusBuyoutBuy"),
          typeEvent_id: 11527,
        },
        {
          currentStatus: this.$t("trackingStatusBuyoutDone"),
          typeEvent_id: 11528,
        },
      ],
      werehouseList: [
        {
          currentStatus: this.$t("trackingStatusProcessWait"),
          typeEvent_id: [11461],
        },
        {
          currentStatus: this.$t("trackingStatusProcessArrive"),
          typeEvent_id: [11462],
        },
        {
          currentStatus: this.$t("trackingStatusProcessDone"),
          typeEvent_id: [11840],
          // , 11598
        },
      ],
      deliveryList: [
        {
          currentStatus: this.$t("trackingStatusDeliveryInclude"),
          typeEvent_id: [11463],
        },
        {
          currentStatus: this.$t("trackingStatusDeliveryLocal"),
          typeEvent_id: [11470],
        },
        {
          currentStatus: this.$t("trackingStatusDeliverySended"),
          typeEvent_id: [11464],
        },
        // {
        //   currentStatus: this.$t("trackingStatusDeliveryPreArrive"),
        //   typeEvent_id: [11465],
        // },
        {
          currentStatus: this.$t("trackingStatusDeliveryArrived"),
          typeEvent_id: [11466],
        },
        {
          currentStatus: this.$t("trackingStatusDeliveryCheck"),
          typeEvent_id: [11504],
          isOptional: true,
        },
        {
          currentStatus: this.$t("trackingStatusDeliveryPending"),
          typeEvent_id: [11507],
          isOptional: true,
        },
        {
          currentStatus: this.$t("trackingStatusDeliveryOnNP"),
          typeEvent_id: [11468],
        },
        {
          currentStatus: this.$t("trackingStatusDeliveryToClient"),
          typeEvent_id: [11468],
        },
        {
          currentStatus: this.$t("trackingStatusDeliveryClient"),
          typeEvent_id: [11673, 11674],
        },
        {
          currentStatus: this.$t("trackingStatusDeliveryFinal"),
          typeEvent_id: [11469],
        },
      ],
    };
  },
  methods: {
    onBack() {
      this.onClose();
    },
  },
  computed: {
    ...mapGetters("user", ["user"]),
    currentStep() {
      const werehouseStatus =
        this.werehouseList.find(({ isActive }) => isActive) || {};
      const deliveryStatus =
        this.deliveryList.find(({ isActive }) => isActive) || {};

      if (deliveryStatus.isActive) return 3;
      if (werehouseStatus.isActive) return 2;
      return this.buyoutId ? 1 : 2;
    },
    deliveryTime() {
      const { eventTime: firstTime } =
        this.deliveryList.find((el) => el.isActive && el.eventTime) || {};
      if (!firstTime || this.isDelivered) return "";
      const duration = this.$moment.duration(
        this.$moment().diff(this.$moment(firstTime))
      );
      return `${duration.humanize()} ${this.$t("trackingInWay")}`;
    },
    arrivedDaysText() {
      return `${this.$t("getOnPC")} ${getNumEnding(this.arrivedDaysCount, [
        this.$t("dayPC"),
        this.$t("days3PC"),
        this.$t("daysPC"),
      ])}`;
    },
    isDelivered() {
      return (
        this.deliveryStep === 63 ||
        this.stateId === 5013 ||
        this.stateId === -5003
      );
    },
    userHomeCountry() {
      return this.user.pr201;
    },
  },
  async mounted() {
    if (this.buyoutId) {
      const buyoutId = `${this.buyoutId}`;
      const buyoutData = await getTrackingStatus(buyoutId);
      // this.buyoutList = buyoutData.map((d) => ({
      //   currentStatus: "",
      //   eventTime: d.DT_UTC_Log,
      // }));
      this.buyoutList = this.buyoutList.map((itm) => {
        const sameStatus = buyoutData.find(
          ({ TypeEvent_id }) => TypeEvent_id === itm.typeEvent_id
        );
        if (!sameStatus) return itm;
        return {
          eventTime: sameStatus.DT_UTC_Log,
          ...itm,
          isActive: true,
        };
      });

      const lastBuyoutStatus = buyoutData.find(
        ({ TypeEvent_id }) => TypeEvent_id === 11528
      );
      if (lastBuyoutStatus) {
        this.werehouseList = this.werehouseList.map((item) => {
          if (item.typeEvent_id === 11528)
            return {
              ...item,
              isActive: true,
            };
          return item;
        });
      }
    }
    const trackingData = await getTrackingStatusList(this.tracking);
    this.werehouseList = updateTrackingList(this.werehouseList, trackingData);
    // this.deliveryList = updateTrackingList(this.deliveryList, trackingData);
    this.deliveryList = trackingData
      .filter(
        (d) =>
          ![
            11598,
            ...this.werehouseList.map((d) => d.typeEvent_id).flat(),
            ...this.buyoutList.map((d) => d.typeEvent_id),
          ].includes(d.typeEvent_id)
      )
      .map((d) => ({ ...d, isActive: true }));
    // this.werehouseList = trackingData;
  },
};
</script>

<style>
.parcel-tracking {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 22;
  background: #fff;
  overflow: auto;
}
.parcel-tracking .parcel-status {
  padding-top: 10px;
  border-bottom: 0 solid #000;
}
.parcel-tracking__header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 40px;
  padding-right: 40px;
  position: relative;
  background: #282828;
  color: #fff;
  height: 44px;
  font-size: 16px;
  font-family: "Helvetica Neue Medium";
  padding-top: env(safe-area-inset-top);
  padding-top: constant(safe-area-inset-top);
}
.parcel-tracking__header:after {
  background: #282828;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 300px;
  content: "";
  display: block;
  z-index: -1;
}
.parcel-tracking__back {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  top: env(safe-area-inset-top);
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.parcel-tracking__content {
  padding-top: 20px;
  padding-bottom: 40px;
  padding-left: 16px;
  padding-right: 16px;
}
.parcel-tracking__status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 26px;
}
.parcel-tracking__status-to img,
.parcel-tracking__status-from img {
  width: 24px;
}
.parcel-tracking__status-left,
.parcel-tracking__status-right {
  height: 2px;
  background: #bfbfbf;
  flex: 1 auto;
  position: relative;
}
.isActive.parcel-tracking__status-left,
.isActive.parcel-tracking__status-right,
.isActive.parcel-tracking__status-left:after,
.isActive.parcel-tracking__status-right:after {
  background: #91c258;
}
.parcel-tracking__status-left {
  margin-left: 12px;
}
.parcel-tracking__status-right {
  margin-right: 12px;
}
.parcel-tracking__status-left:after,
.parcel-tracking__status-right:after {
  background: #bfbfbf;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  content: "";
  display: block;
  border-radius: 50%;
}
.parcel-tracking__status-left:after {
  left: 0;
}
.parcel-tracking__status-right:after {
  right: 0;
}
.parcel-tracking__status-plane {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  fill: #bfbfbf;
  z-index: 2;
}
.isActive.parcel-tracking__status-plane {
  fill: #91c258;
}
.isFinal.parcel-tracking__status-plane {
  left: auto;
  right: 29px;
}
</style>
