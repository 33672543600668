<template>
  <div class="parcel-info__container">
    <transition name="fast-fade">
      <div v-if="!isFullDataLoaded || isLoading" class="loadingScreen" />
    </transition>
    <div v-if="isFullDataLoaded" class="parcel-info">
      <parcel-header
        :country="parcelFullData.pr_587 || parcelFullData.pr275_Name"
      />
      <parcel-gallery
        :additionalImg="additionalImg"
        :isReadyToDelivery="isReadyToDelivery"
        :parcelId="parcelId"
      />
      <div class="parcel-info__content">
        <parcel-helpers :parcelId="parcelId" />
        <!-- @click="isEditNameOpened = true" -->
        <div class="parcel-info__title">
          {{ parcelTitle || $t("needDescriptionPC") }}
        </div>

        <parcel-status
          :totalCost="parcelFullData.TotalCost"
          :shopStep="parcelFullData.StepN"
          :deliveryStep="parcelFullData.p_StepN"
          :stateId="parcelFullData.p_State_id"
          :isProductListEmpty="isNeedDescription"
          :arrivedDate="werehouseArrivedDate"
          :arrivedDaysCount="parcelFullData.QtyDayInWay || 0"
        />

        <parcel-entity
          v-if="isOnCustomsProccess && parcelFullData.TrackCodeTax"
          :label="$t('cardEntityCustomsNumber')"
          :value="parcelFullData.TrackCodeTax"
          type="copy"
          isRedLink
        >
          <img slot="action-icon" src="@/assets/img/parcel/Copy.svg" />
        </parcel-entity>

        <parcel-product-list
          v-if="productList.length"
          :productList="productList"
          :onEdit="onEditProduct"
          :totalPrice="totalProductsPrice"
          :isEditable="isReadyToDelivery"
        />

        <parcel-new-products
          v-if="isNeedDescription"
          :addedProducts.sync="addedProducts"
        />

        <parcel-link v-if="parcelFullData.pr451" :link="parcelFullData.pr451" />

        <parcel-tax v-if="customsServiceResult" :count="customsServiceResult" />

        <parcel-entity
          v-if="
            deliveryDays &&
              (isReadyToDelivery || isOnMoveToWerehouse || isOnBuy)
          "
          :label="$t('cardEntityWaitingInUkraine')"
          :value="deliveryDays"
          :customAction="openTracking"
          :onOpen="openTracking"
        >
          <img slot="base-icon" src="@/assets/img/parcel/Time.svg" />
          <img slot="action-icon" src="@/assets/img/parcel/ArrRight.svg" />
        </parcel-entity>
        <parcel-entity
          v-if="getDeliveryDates && (isOnDelivery || isOnCustomsProccess)"
          :label="$t('cardEntityWaitingInUkraine')"
          :value="getDeliveryDates"
          :additionalInfo="getDaysShift"
          :customAction="openTracking"
          :onOpen="openTracking"
        >
          <img slot="base-icon" src="@/assets/img/parcel/Time.svg" />
          <img slot="action-icon" src="@/assets/img/parcel/ArrRight.svg" />
        </parcel-entity>

        <parcel-entity
          v-if="parcelFullData.pr265_Name"
          :label="$t('cardEntityLocation')"
          :value="parcelFullData.LocalState || parcelFullData.pr265_Name"
          :customAction="openTracking"
          :onOpen="openTracking"
        >
          <img slot="base-icon" src="@/assets/img/parcel/Navigate.svg" />
          <img
            v-if="isHasTracking"
            slot="action-icon"
            src="@/assets/img/parcel/ArrRight.svg"
          />
        </parcel-entity>
        <parcel-entity
          v-if="
            parcelFullData.p_pr_296 ||
              parcelFullData.pr_296 ||
              parcelFullData.pr294
          "
          :label="$t('cardEntityParcelNumber')"
          :value="
            parcelFullData.p_pr_296 ||
              parcelFullData.pr_296 ||
              parcelFullData.pr294
          "
          type="copy"
        >
          <img slot="base-icon" src="@/assets/img/parcel/EG.svg" />
          <img slot="action-icon" src="@/assets/img/parcel/Copy.svg" />
        </parcel-entity>
        <parcel-entity
          v-if="parcelFullData.pr299"
          :label="$t('cardEntityLocalTracking')"
          :value="parcelFullData.pr299"
          type="copy"
        >
          <img slot="base-icon" src="@/assets/img/parcel/Earth.svg" />
          <img slot="action-icon" src="@/assets/img/parcel/Copy.svg" />
        </parcel-entity>
        <parcel-entity
          v-if="parcelFullData.pr325"
          :label="$t('cardEntityWeight')"
          :value="`${parcelFullData.pr325} ${$t('kg')}`"
          :customAction="openVolumeWeight"
          :onOpen="openVolumeWeight"
        >
          <img slot="base-icon" src="@/assets/img/parcel/Weight.svg" />
          <img slot="action-icon" src="@/assets/img/parcel/Info.svg" />
        </parcel-entity>
        <parcel-entity
          v-if="pricesData.countryDelivery.Cost && !isReadyToDelivery"
          :label="$t('cardEntityPrice')"
          :value="`$${pricesData.countryDelivery.Cost}`"
        >
          <img slot="base-icon" src="@/assets/img/parcel/Money.svg" />
        </parcel-entity>
        <parcel-entity
          v-if="isOnBuy && globalDeliveryWeight"
          :label="$t('cardEntityAboutWeight')"
          :value="`${globalDeliveryWeight} ${$t('kg')}`"
          type="info"
          :infoText="$t('cardEntityAboutWeightDescription')"
        >
          <img slot="base-icon" src="@/assets/img/parcel/Weight.svg" />
          <img slot="action-icon" src="@/assets/img/parcel/Info.svg" />
        </parcel-entity>
        <parcel-entity
          v-if="
            isOnBuy && roughDeliveryCost && !pricesData.countryDelivery.Cost
          "
          :label="$t('cardEntityAboutPrice')"
          :value="`$${roughDeliveryCost}`"
          type="info"
          :infoText="$t('cardEntityAboutPriceDescription')"
        >
          <img slot="base-icon" src="@/assets/img/parcel/Money.svg" />
          <img slot="action-icon" src="@/assets/img/parcel/Info.svg" />
        </parcel-entity>

        <parcel-entity
          v-if="
            isReadyToDelivery ||
              isOnDelivery ||
              isOnCustomsProccess ||
              isOnProcessNP ||
              isOnFinal
          "
          :label="$t('cardEntityTotalPrice')"
          :value="`$${totalPrice}`"
          :isSuccess="true"
          type="info"
          :customAction="openTotalCheck"
        >
          <img slot="base-icon" src="@/assets/img/parcel/Calculator.svg" />
          <img slot="action-icon" src="@/assets/img/parcel/Info.svg" />
        </parcel-entity>

        <parcel-entity
          v-if="(isOnProcessNP || isOnFinal) && parcelFullData.TrackCodeTo"
          :label="$t('cardEntityNumberNP')"
          :value="parcelFullData.TrackCodeTo"
          :isLink="true"
          type="copy"
          :onOpen="openCargoNP"
        >
          <img slot="base-icon" src="@/assets/img/parcel/NewPost.svg" />
          <img slot="action-icon" src="@/assets/img/parcel/Copy.svg" />
        </parcel-entity>

        <div
          v-if="
            parcelFullData.pr595 ||
              pricesData.combine.Cost ||
              pricesData.divide.Cost
          "
          class="parcel-active-service"
        >
          <div class="parcel-active-service__header parcel-item__header">
            <span>{{ $t("cardUsedServiceTitle") }}</span>
          </div>
          <div class="parcel-active-service__content">
            <parcel-entity
              v-if="pricesData.divide.Cost"
              :label="$t('cardUsedDivideName')"
              :value="$t('cardUsedDivideResult')"
            >
              <img slot="base-icon" src="@/assets/img/parcel/Split.svg" />
            </parcel-entity>
            <parcel-entity
              v-if="pricesData.combine.Cost"
              :label="$t('cardUsedCombineName')"
              :value="$t('cardUsedCombineResult')"
            >
              <img slot="base-icon" src="@/assets/img/parcel/Union.svg" />
            </parcel-entity>
            <parcel-entity
              :label="$t('cardUsedInsuranceName')"
              :value="$t('cardUsedInsuranceResult')"
              v-if="parcelFullData.pr595"
            >
              <img slot="base-icon" src="@/assets/img/parcel/Shield.svg" />
            </parcel-entity>
          </div>
        </div>

        <parcel-address
          v-if="
            isReadyToDelivery ||
              isOnDelivery ||
              isOnCustomsProccess ||
              isOnFinal
          "
          :currentAddress="parcelFullData.pr489"
          :onSelect="onSelectAddress"
          :isEditable="isReadyToDelivery"
          :customerName="parcelFullData.NameCustomer"
          :localId="parcelFullData.TrackCodeTo"
        />

        <parcel-additional-service
          v-if="isReadyToDelivery"
          :onCombine="onCombine"
          :onDivide="onDivide"
          :onChangeInsurance="onChangeInsurance"
          :isInsuranceEnabled="!!this.parcelFullData.pr595"
          :pricesData="pricesData"
          :insurancePrice="insurancePrice"
          :combinePrice="combinePrice"
          :dividePrice="dividePrice"
        />

        <parcel-payment
          v-if="parcelFullData.StepN === 2"
          :helpText="$t('cardPaymentHelpText')"
          :totalCost="`${parcelFullData.TotalCost}`"
          :isLoading="isUpdateLoading"
          :onPay="onPayBuyout"
        />

        <parcel-payment
          v-if="isReadyToDelivery"
          :totalCost="`${pricesData.countryDelivery.TotalCost}`"
          :isLoading="isUpdateLoading"
          :onPay="onPayParcel"
          :isDisabled="!this.parcelFullData.pr489"
        />

        <div
          v-if="parcelFullData.p_StepN === 53"
          class="parcel-info__main-action"
        >
          <div
            class="parcel-payment__btn btnWideRound"
            @click="onCustomsProccess"
          >
            {{ $t("btnToCustomsCheck") }}
          </div>
        </div>
        <div v-if="isNeedDescription" class="parcel-info__main-action">
          <div
            class="parcel-payment__btn btnWideRound"
            :class="{ disabled: !isCanSaveDescription }"
            @click="onSaveDescription"
          >
            {{ $t("btnSaveAndGo") }}
          </div>
        </div>

        <!-- <parcel-edit-name
          :name="parcelTitle"
          :isOpened.sync="isEditNameOpened"
        /> -->
        <parcel-edit-product
          :product="editProduct"
          :isOpened.sync="isEditProductOpened"
          :onSubmit="onSaveProduct"
        />

        <parcel-check
          :isOpened.sync="isCheckOpened"
          :isBuyout="isBuyout"
          :pricesData="pricesData"
          :totalProductsPrice="totalProductsPrice"
          :customsServiceResult="customsServiceResult"
          :totalPrice="totalPrice"
          :isFullPaid="
            isOnDelivery || isOnCustomsProccess || isOnProcessNP || isOnFinal
          "
          :isCustomsPaid="isOnFinal"
        />

        <parcel-volume-weight
          :isOpened.sync="isVolumeWeightOpened"
          :factWeight="parcelFullData.pr325"
          :volumeWeight="parcelFullData.pr870"
          :paymentWeight="parcelFullData.pr273"
          :width="parcelFullData.pr582"
          :height="parcelFullData.pr581"
          :length="parcelFullData.pr583"
        />
      </div>

      <parcel-tracking
        :country="parcelFullData.pr_587 || parcelFullData.pr275_Name"
        :tracking="
          parcelFullData.p_pr_296 ||
            parcelFullData.pr_296 ||
            parcelFullData.pr294
        "
        :buyoutId="parcelFullData.iParcel_id"
        :deliveryStep="parcelFullData.p_StepN"
        :stateId="parcelFullData.p_State_id"
        :arrivedDaysCount="parcelFullData.QtyDayInWay || 0"
        v-if="isTrackingOpened"
        :onClose="() => (isTrackingOpened = false)"
      >
        <parcel-entity
          v-if="
            deliveryDays &&
              (isReadyToDelivery || isOnMoveToWerehouse || isOnBuy)
          "
          :label="$t('cardEntityWaitingInUkraine')"
          :value="deliveryDays"
        >
          <img slot="base-icon" src="@/assets/img/parcel/Time.svg" />
        </parcel-entity>
        <parcel-entity
          v-if="getDeliveryDates && (isOnDelivery || isOnCustomsProccess)"
          :label="$t('cardEntityWaitingInUkraine')"
          :value="getDeliveryDates"
          :additionalInfo="getDaysShift"
        >
          <img slot="base-icon" src="@/assets/img/parcel/Time.svg" />
        </parcel-entity>
        <parcel-entity
          v-if="
            parcelFullData.p_pr_296 ||
              parcelFullData.pr_296 ||
              parcelFullData.pr294
          "
          :label="$t('cardEntityParcelNumber')"
          :value="
            parcelFullData.p_pr_296 ||
              parcelFullData.pr_296 ||
              parcelFullData.pr294
          "
          type="copy"
        >
          <img slot="base-icon" src="@/assets/img/parcel/EG.svg" />
          <img slot="action-icon" src="@/assets/img/parcel/Copy.svg" />
        </parcel-entity>
      </parcel-tracking>
    </div>
  </div>
</template>

<script>
import ParcelHeader from "./ParcelHeader.vue";
import ParcelGallery from "./ParcelGallery.vue";
import ParcelHelpers from "./ParcelHelpers.vue";
import ParcelStatus from "./ParcelStatus.vue";
import ParcelTax from "./ParcelTax.vue";
import ParcelLink from "./ParcelLink.vue";
import ParcelProductList from "./ParcelProductList.vue";
import ParcelEntity from "./ParcelEntity.vue";
import ParcelAddress from "./ParcelAddress.vue";
import ParcelAdditionalService from "./ParcelAdditionalService.vue";
import ParcelPayment from "./ParcelPayment.vue";
import ParcelEditProduct from "./ParcelEditProduct.vue";
import ParcelNewProducts from "./ParcelNewProducts.vue";
import ParcelCheck from "./ParcelCheck.vue";
import ParcelTracking from "./ParcelTracking.vue";
import ParcelVolumeWeight from "./ParcelVolumeWeight.vue";

import { getMediaLink, openExternal, removeEmpty } from "@/utils/utils";
import { mapActions, mapGetters } from "vuex";
import getShoppingCost, {
  changeAddress,
  changeInsurance,
  getParcelPrice,
  parseShoppingCost,
  saveEditedProduct,
} from "../../services/requests-list";
import eventsRegister from "@/services/events-register";

export default {
  name: "ParcelInfo",
  components: {
    ParcelHeader,
    ParcelGallery,
    ParcelHelpers,
    ParcelStatus,
    ParcelTax,
    ParcelLink,
    ParcelProductList,
    ParcelEntity,
    ParcelAddress,
    ParcelAdditionalService,
    ParcelPayment,
    ParcelEditProduct,
    ParcelNewProducts,
    ParcelCheck,
    ParcelTracking,
    ParcelVolumeWeight,
  },
  data() {
    return {
      parcelId: +this.$route.params.parcelId,
      parcelData: {},
      pricesData: {
        innerDelivery: {},
        werehouse: {},
        countryDelivery: {},
        insurance: {},
        localDelivery: {},
        combine: {},
        divide: {},
        additionalBox: {},
        additionalPhoto: {},
        productPrice: {},
        productTax: {},
        buyout: {},
      },

      addedProducts:
        JSON.parse(
          window.localStorage.getItem(`parcelAddedProducts${this.parcelId}`)
        ) || [],

      newParcelName: "",

      isLoading: false,
      isUpdateLoading: false,
      isFullDataLoaded: true,
      isCheckOpened: false,
      isVolumeWeightOpened: false,
      isEditNameOpened: false,
      isTrackingOpened: false,

      isEditProductOpened: false,
      editProduct: {},

      roughDeliveryCost: 0,
      globalDeliveryWeight: 0,
      globalDeliveryTariff: 0,

      insurancePrice: 0,
    };
  },
  computed: {
    ...mapGetters("parcels", [
      "parcelById",
      "wholeParcelList",
      "innerPackagesList",
      "isHasCombineParcels",
    ]),
    ...mapGetters("root", [
      "dutyFreeLimit",
      "freeTaxLimit",
      "customsService",
      "combinePrice",
      "dividePrice",
    ]),
    ...mapGetters("user", ["userCountry"]),
    userIsFromUSA() {
      return this.userCountry === 284;
    },
    werehouseArrivedDate() {
      return this.$moment(this.parcelFullData.pr491).calendar(null, {
        sameElse: "DD.MM.YYYY",
      });
    },
    parcelFullData() {
      console.log("parcelFullData", {
        ...removeEmpty(this.parcelById(this.parcelId)),
        ...removeEmpty(this.parcelData),
      });
      return {
        ...removeEmpty(this.parcelById(this.parcelId)),
        ...removeEmpty(this.parcelData),
      };
    },
    deliveryDays() {
      let toReturn = this.parcelFullData.Qty_day_min
        ? this.parcelFullData.Qty_day_min
        : "";
      if (this.parcelFullData.Qty_day_max) {
        if (this.parcelFullData.Qty_day_min)
          toReturn +=
            "-" + this.parcelFullData.Qty_day_max + " " + this.$t("daysPC");
        else
          toReturn = this.parcelFullData.Qty_day_max + " " + this.$t("daysPC");
      }

      return toReturn;
    },
    getDeliveryDates() {
      const { D_min, D_max, nShift } = this.parcelFullData;
      const dateMin = new Date(D_min);
      const dayMin = ("0" + dateMin.getDate()).slice(-2);
      const monthMin = ("0" + (dateMin.getMonth() + 1)).slice(-2);

      let toReturn = "";
      if (D_min) toReturn = dayMin + "." + monthMin;
      if (nShift && nShift > 0) toReturn = "";

      if (D_max) {
        const dateMax = new Date(D_max);
        const dayMax = ("0" + dateMax.getDate()).slice(-2);
        const monthMax = ("0" + (dateMax.getMonth() + 1)).slice(-2);
        if (toReturn) toReturn += " - " + dayMax + "." + monthMax;
        else toReturn = dayMax + "." + monthMax;
      }

      return toReturn;
    },
    getDaysShift() {
      const { nShift } = this.parcelFullData;
      if (nShift && nShift > 0) {
        let days = this.$t("daysPC");
        if (nShift > 4 && nShift < 21) days = this.$t("daysPC");
        // 5-20 days
        else if (nShift % 10 === 1) days = this.$t("dayPC");
        // 1, 11, 12 .... except 11
        else if (nShift % 10 > 1 && nShift % 10 < 5) days = this.$t("days3PC"); // 2,3,4, 22,23,24, 32,33,34 except 11

        return " +" + nShift + " " + days;
      } else return "";
    },
    isBuyout() {
      return !!this.parcelFullData.iParcel_id;
    },
    isOnBuy() {
      return [1, 2, 3].includes(this.parcelFullData.StepN);
    },
    isOnDelivery() {
      return (
        [3, 4, 51, 62].includes(this.parcelFullData.p_StepN) &&
        ![5030, 5034, 5026, 5029, 5017, 5019].includes(
          this.parcelFullData.p_State_id
        )
      );
    },
    isOnFinal() {
      const { p_State_id, State_id, Status_id } = this.parcelFullData;
      return (
        [-5003, 5013].includes(p_State_id) ||
        [-5003].includes(State_id) ||
        [11693, 11694].includes(Status_id)
      );
    },
    isOnProcessNP() {
      return this.parcelFullData.p_StepN === 61;
    },
    isOnMoveToWerehouse() {
      return [5026, 5001, 5017].includes(this.parcelFullData.p_State_id);
    },
    isInWerehouse() {
      const { State_id, p_State_id } = this.parcelFullData;
      return (
        ([5020, 5017].includes(p_State_id) || [5020].includes(State_id)) &&
        !this.isOnBuy
      );
    },
    isReadyToDelivery() {
      const { State_id, p_State_id, p_StepN } = this.parcelFullData;
      return (
        ([5020].includes(p_State_id) || [5020].includes(State_id)) &&
        !this.isOnBuy &&
        ![3].includes(p_StepN)
      );
    },
    isOnCustomsProccess() {
      return [52, 53].includes(this.parcelFullData.p_StepN);
    },
    isCanBeCombined() {
      if (!this.isHasCombineParcels[this.parcelFullData.pr_587]) return false;
      return this.isInWerehouse;
    },
    isNeedDescription() {
      return (
        !this.parcelFullData.p331 &&
        !this.productList.length &&
        this.parcelFullData.p_State_id === 5017
      );
    },
    isCanSaveDescription() {
      return !!this.addedProducts.length;
    },
    additionalImg() {
      return this.parcelFullData.pr_600
        ? getMediaLink(this.parcelFullData.pr_600)
        : "";
    },
    deliveryProducts() {
      const list = this.innerPackagesList
        .filter((el) => +el.Package_id === +this.parcelId)
        .map((el) => ({
          price: el.pr249,
          count: el.pr256,
          name: el.pr474,
          id: el.pr_309,
        }));
      return list;
    },
    productList() {
      if (this.isOnBuy) {
        const {
          pr256: count,
          pr474: name,
          pr444: model,
          pr446: size,
          pr445: color,
          TotalCost,
        } = this.parcelFullData;
        return [{ name, color, size, model, count, price: TotalCost }];
      } else if (this.isBuyout) {
        const { pr444: model, pr446: size, pr445: color } = this.parcelFullData;
        return [{ ...this.deliveryProducts[0], model, size, color }];
      } else {
        return this.deliveryProducts;
      }
    },
    totalProductsPrice() {
      if (!this.isBuyout) {
        return 0;
      }
      const total = this.productList.reduce(
        (sum, productItem) => sum + productItem.price,
        0
      );

      return this.$options.filters.numberRound(total);
    },
    customsServiceResult() {
      if (this.userIsFromUSA) return 0;
      const dutyTax =
        this.totalProductsPrice - this.dutyFreeLimit > 0
          ? (this.totalProductsPrice - this.dutyFreeLimit) * 0.1
          : 0;
      const additionalTax =
        this.totalProductsPrice - this.freeTaxLimit > 0
          ? (this.totalProductsPrice - this.freeTaxLimit + dutyTax) * 0.2
          : 0;
      const result =
        dutyTax + additionalTax > 0
          ? dutyTax + additionalTax + this.customsService
          : 0;
      return this.$options.filters.numberRound(result);
    },
    totalPrice() {
      const price =
        this.totalProductsPrice +
        this.pricesData.countryDelivery.Cost +
        this.pricesData.innerDelivery.Cost +
        this.pricesData.localDelivery.Cost +
        (this.pricesData.buyout.Cost || 0) +
        this.pricesData.combine.Cost +
        this.pricesData.divide.Cost +
        this.pricesData.additionalBox.Cost +
        this.pricesData.additionalPhoto.Cost +
        this.pricesData.insurance.Cost +
        this.customsServiceResult;
      return this.$options.filters.numberRound(price);
    },
    parcelTitle() {
      return (
        this.parcelFullData.p_pr_235 ||
        this.parcelFullData.pr_235 ||
        this.parcelFullData.pr474 ||
        this.parcelFullData.pr331
      );
    },
    isPayParcelDisabled() {
      return !this.parcelFullData.pr489;
    },
    isHasTracking() {
      return !!this.parcelFullData.pr294;
    },
    isNeedTotalPrice() {
      return (
        this.isInWerehouse ||
        this.isOnDelivery ||
        this.isOnCustomsProccess ||
        this.isOnProcessNP ||
        this.isOnFinal
      );
    },
  },
  async mounted() {
    if (this.isBuyout) {
      this.parseBuyoutData();
      this.getBuyoutPrices();
    }
    if (this.isNeedTotalPrice) {
      await Promise.all([this.getPrices(), this.getInsurancePrice()]);
    }
  },
  methods: {
    ...mapActions("parcels", ["loadInnerPackages"]),
    async getPrices() {
      const res = await getParcelPrice(
        this.parcelId,
        this.parcelFullData.pr489,
        this.parcelFullData.pr595
      ).catch((res) => res);
      this.pricesData = { ...this.pricesData, ...res };
    },
    async getBuyoutPrices() {
      const res = await getShoppingCost(this.parcelFullData.iParcel_id).catch(
        (res) => res
      );
      this.pricesData = { ...this.pricesData, ...res };
    },
    async getInsurancePrice() {
      const res = await getParcelPrice(
        this.parcelId,
        this.parcelFullData.pr489,
        true
      ).catch((res) => res);
      this.insurancePrice = res.insurance.Cost;
    },
    // async getParcelData() {
    //   const { parcelData, deliveryProducts } = await getParcelDataReq(
    //     this.parcelId
    //   ).catch((res) => res);
    //   this.parcelData = { ...this.parcelData, ...parcelData };
    //   this.deliveryProducts = deliveryProducts;
    // },
    async onSelectAddress(id) {
      this.isUpdateLoading = true;
      await changeAddress(this.parcelId, id).catch((res) => res);
      this.parcelFullData.pr489 = id;
      await this.getPrices();
      this.isUpdateLoading = false;
    },
    async onChangeInsurance(val) {
      this.isUpdateLoading = true;
      await changeInsurance(this.parcelId, val).catch((res) => res);
      this.parcelFullData.pr595 = val;
      await this.getPrices();
      this.isUpdateLoading = false;
    },
    parseBuyoutData() {
      if (!this.parcelFullData.pr505) return false;
      const { result = [] } = JSON.parse(this.parcelFullData.pr505);

      const roughDeliveryCost =
        result.find((el) => +el.TypeCost_id === 11520) || {};
      if (roughDeliveryCost) {
        this.roughDeliveryCost = roughDeliveryCost.Cost;
        this.globalDeliveryWeight = roughDeliveryCost.Base;
        this.globalDeliveryTariff = roughDeliveryCost.Tariff;
      }
      this.pricesData = { ...this.pricesData, ...parseShoppingCost(result) };
    },
    onEditProduct(id) {
      if (!this.isInWerehouse) return false;

      this.editProduct = {
        index: id,
        ...this.productList[id],
      };
      this.isEditProductOpened = true;
    },
    onPayBuyout() {
      this.$root.$emit("showPayment", {
        type: "shopping",
        parcel: {
          id: this.parcelId,
          ...this.parcelFullData,
        },
      });
    },
    onPayParcel() {
      if (!this.parcelFullData.pr489) {
        return this.$root.$emit(eventsRegister.addMessage, {
          type: "error",
          message: this.$t("cardMissedAddressError"),
          autoClose: true,
        });
      }
      this.$root.$emit("showPayment", {
        type: "parcel",
        parcel: {
          id: this.parcelId,
          ...this.parcelFullData,
        },
        param1: this.parcelFullData.pr489,
        param2: this.parcelFullData.pr595,
      });
    },
    async onSaveProduct(newData) {
      this.isLoading = true;
      await saveEditedProduct(this.parcelId, [newData], "U").catch(
        (res) => res
      );
      await this.getInsurancePrice();
      await this.loadInnerPackages();
      // this.productList = this.productList.map((item, index) =>
      //   newData.index === index ? newData : item
      // );
      this.isLoading = false;
    },
    async onSaveDescription() {
      if (!this.isCanSaveDescription) {
        this.$root.$emit(eventsRegister.addMessage, {
          type: "error",
          message: this.$t("cardDescriptionParcelError"),
          autoClose: true,
        });
      }
      this.isLoading = true;
      await saveEditedProduct(this.parcelId, this.addedProducts, "I").catch(
        (res) => res
      );
      await this.loadInnerPackages();
      await this.getInsurancePrice();

      this.$root.$emit(eventsRegister.updateParcels, () => {
        this.isLoading = false;
      });
    },
    onCombine() {
      if (!this.isCanBeCombined) {
        this.$root.$emit(eventsRegister.addMessage, {
          type: "error",
          message: this.$t("cardCombineParcelError"),
          autoClose: true,
        });
      } else {
        this.$root.$emit(eventsRegister.showPopup, {
          body: this.parcelFullData,
          header: this.$t("combinePu"),
          type: "combine",
        });
      }
    },
    onDivide() {
      if (!this.parcelFullData.isAllowDivide) {
        this.$root.$emit(eventsRegister.addMessage, {
          type: "error",
          message: this.$t("cardDivideParcelError"),
          autoClose: true,
        });
      } else {
        this.$root.$emit(eventsRegister.showPopup, {
          body: this.parcelFullData,
          header: this.$t("dividingParcel"),
          type: "parcelDivide",
        });
      }
    },
    openTotalCheck() {
      this.isCheckOpened = true;
    },
    openTracking() {
      // if (!this.isHasTracking) return false;
      this.isTrackingOpened = true;
      // this.$root.$emit(eventsRegister.showPopup, {
      //   body: this.parcelFullData,
      //   header: this.$t("trackCargoPu") + " " + this.parcelFullData.pr294,
      //   type: "cargoTrack",
      // });
    },
    openVolumeWeight() {
      this.isVolumeWeightOpened = true;
    },
    onCustomsProccess() {
      openExternal("https://easyget.com.ua/mytnyi-zbir");
    },
    openCargoNP() {
      openExternal(
        `https://novaposhta.ua/tracking/?cargo_number=${this.parcelFullData.TrackCodeTo}`
      );
    },
  },
  watch: {
    async wholeParcelList() {
      this.$forceUpdate();
    },
  },
};
</script>

<style>
.parcel-info {
  display: flex;
  flex-direction: column;
  background: #f7f5f8;
  margin: -84px -16px -98px;
  min-height: 100vh;
  position: relative;
}
.parcel-info__container .loadingScreen::before {
  opacity: 1;
}
.parcel-info img {
  display: block;
}
.parcel-gallery__empty {
  height: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
  flex-direction: column;
}
.parcel-info__content {
  background: #ffffff;
  position: relative;
  z-index: 2;
  box-shadow: 8px 0px 12px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  flex: 1 0 auto;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 20px;
}
.parcel-info__title {
  font-size: 24px;
  line-height: 1.4;
  color: #282828;
  font-family: "Helvetica Neue Medium";
  font-weight: bold;
  padding-top: 12px;
  padding-bottom: 12px;
}
.parcel-info__main-action {
  padding-top: 30px;
}
</style>
