<template>
  <div class="parcel-info__header">
    <div class="parcel-info__back" @click="onBack()">
      <img src="@/assets/img/arr-l-w.svg" />
    </div>
    <div class="parcel-info__country">
      <img v-if="country === 'US'" src="@/assets/img/parcel/USA.svg" />
      <img v-if="country === 'CN'" src="@/assets/img/parcel/China.svg" />
      <img v-if="country === 'TR'" src="@/assets/img/i-turkey.svg" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ParcelHeader",
  props: {
    country: String,
  },
  methods: {
    onBack() {
      // this.$root.$emit(eventsRegister.updateParcels);
      this.$router.push('/');
    }
  },
};
</script>

<style>
.parcel-info__header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
}
.parcel-info__back {
  background: #000;
  border-radius: 50%;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.parcel-info__back:after {
  content: '';
  position: absolute;
  height: 50px;
  width: 50px;
  display: block;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.parcel-info__back img {
  width: 5px;
  margin-left: -2px;
}
</style>