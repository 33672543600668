<template>
  <div class="parcel-helpers">
    <div
      class="parcel-helpers__item parcel-helpers__item--help"
      @click="isSocialOpened = !isSocialOpened"
    >
      <img src="@/assets/img/parcel/Help.svg" />
    </div>
    <!-- <div class="parcel-helpers__item" @click="goToNotify">
      <img src="@/assets/img/parcel/Notification.svg" />
    </div> -->

    <social-help
      :parcelId="this.parcelId"
      :isSocialOpened.sync="isSocialOpened"
    />
  </div>
</template>

<script>
import { setRoute } from "@/services/route";
import SocialHelp from "@/components/SocialHelp.vue";

export default {
  name: "ParcelHelpers",
  props: {
    parcelId: Number,
  },
  components: { SocialHelp },
  data() {
    return {
      isSocialOpened: false,
    };
  },
  methods: {
    goToNotify() {
      this.$router.push("/");
      setRoute("events");
    },
  },
};
</script>

<style>
.parcel-helpers {
  display: flex;
  justify-content: flex-end;
  margin-top: -21px;
  position: relative;
}
.parcel-helpers__item {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  margin-left: 17px;
}
.parcel-helpers__item--help {
  position: relative;
  z-index: 4;
}
</style>