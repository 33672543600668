<template>
  <div class="parcel-additional">
    <div class="parcel-additional__header parcel-item__header">
      <span>{{ $t("cardAdditionalServiceName") }}</span>
      <div class="parcel-item__header-action" @click="isInfoOpened = true">
        <img src="@/assets/img/parcel/Info.svg" />
      </div>
    </div>
    <div class="parcel-additional__content">
      <div
        class="parcel-additional__item"
        @click="toggleInsurance"
        :class="{ isActive: isInsuranceEnabled }"
      >
        <div class="parcel-additional__success-icon">
          <img src="@/assets/img/i-success.svg" />
        </div>
        <div class="parcel-additional__img">
          <img src="@/assets/img/parcel/service01.svg" />
        </div>
        <div class="parcel-additional__name">
          {{ $t("cardAdditionalServiceInsurance") }}
        </div>
        <div class="parcel-additional__price">${{ insurancePrice }}</div>
      </div>
      <div class="parcel-additional__item" @click="onDivide">
        <div class="parcel-additional__img">
          <img src="@/assets/img/parcel/service02.svg" />
        </div>
        <div class="parcel-additional__name">
          {{ $t("cardAdditionalServiceDivide") }}
        </div>
        <div class="parcel-additional__price">${{ dividePrice }}</div>
      </div>
      <div class="parcel-additional__item" @click="onCombine">
        <div class="parcel-additional__img">
          <img src="@/assets/img/parcel/service03.svg" />
        </div>
        <div class="parcel-additional__name">
          {{ $t("cardAdditionalServiceCombine") }}
        </div>
        <div class="parcel-additional__price">${{ combinePrice }}</div>
      </div>
    </div>

    <balloon v-if="isInfoOpened" v-bind:isopen.sync="isInfoOpened">
      <h4>{{ $t("cardInsurance") }}</h4>
      <p>{{ $t(`cardInsuranceDescription.${userCountryCode}`) }}</p>
      <h4>{{ $t("cardCombine") }}</h4>
      <p>{{ $t(`cardCombineDescription.${userCountryCode}`) }}</p>
      <h4>{{ $t("cardDivide") }}</h4>
      <p>{{ $t(`cardDivideDescription.${userCountryCode}`) }}</p>
    </balloon>
  </div>
</template>

<script>
import Balloon from "@/components/Balloon.vue";
import { mapGetters } from "vuex";

export default {
  name: "ParcelAdditionalService",
  components: { Balloon },
  props: {
    isInsuranceEnabled: Boolean,
    pricesData: {},
    insurancePrice: Number,
    combinePrice: Number,
    dividePrice: Number,
    onCombine: {
      type: Function,
      default: () => true,
    },
    onDivide: {
      type: Function,
      default: () => true,
    },
    onChangeInsurance: {
      type: Function,
      default: () => true,
    },
  },
  data() {
    return {
      isInfoOpened: false,
    };
  },
  methods: {
    toggleInsurance() {
      this.onChangeInsurance(!this.isInsuranceEnabled);
    },
  },
  computed: {
    ...mapGetters("user", ["userCountryCode"]),
  },
};
</script>

<style>
.parcel-additional__item {
  padding-bottom: 10px;
  border: 2px solid #f8f8f8;
  border-radius: 4px;
  margin-left: 8px;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  width: 32%;
  position: relative;
  transition: 0.2s;
}
.parcel-additional__success-icon {
  right: 10px;
  top: 10px;
  width: 24px;
  height: 24px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #91c258;
  opacity: 0;
  transition: 0.2s;
}
.isActive .parcel-additional__success-icon {
  opacity: 1;
}
.parcel-additional__item.isActive {
  border: 2px solid #91c258;
}
.parcel-additional__content {
  display: flex;
  margin-left: -8px;
  margin-right: -8px;
  padding-top: 16px;
  padding-bottom: 16px;
}
.parcel-additional__name {
  max-width: 100px;
  text-align: center;
  font-size: 12px;
  color: #000000;
  margin-bottom: 4px;
  line-height: 1.4;
}
.parcel-additional__price {
  color: #efb443;
  font-size: 12px;
  font-family: "Helvetica Neue Medium";
  line-height: 14px;
}
.parcel-additional__img {
  margin-bottom: 4px;
}
.parcel-additional__img img {
  max-width: 100%;
}

.parcel-additional .balloonCenterContent h4 {
  margin-bottom: 6px;
  margin-top: 0px;
  font-weight: bold;
}
.parcel-additional .balloonCenterContent p {
  margin-bottom: 20px;
}
</style>
