<template>
  <div class="parcel-link__container">
    <div v-for="(currLink, i) in linkList" :key="i" class="parcel-link">
      <div class="parcel-link__value" @click="onOpen(currLink)">
        <span>{{ currLink }}</span>
      </div>
      <div class="parcel-link__copy" @click="onCopy(currLink)">
        <img src="@/assets/img/parcel/Copy.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import eventsRegister from "@/services/events-register";
import { copyToClipboard, openExternal } from "@/utils/utils";

export default {
  name: "ParcelLink",
  props: {
    link: String,
  },
  computed: {
    linkList() {
      return this.link.split(", ");
    },
  },
  methods: {
    onCopy(link) {
      const clipBoard =
        window["cordova"] && window["cordova"].plugins.clipboard;

      const onSuccess = () => {
        this.$root.$emit(eventsRegister.addMessage, {
          type: "success",
          message: this.$t('copied'),
          autoClose: true,
        });
      };
      if (clipBoard) {
        window["cordova"].plugins.clipboard.copy(link, onSuccess);
      } else {
        copyToClipboard(link, onSuccess);
      }
    },
    onOpen(link) {
      openExternal(link);
    },
  },
};
</script>

<style>
.parcel-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  max-width: 100%;
}
.parcel-link__copy {
  background: #fff;
  min-width: 33px;
  min-height: 30px;
  display: flex;
  justify-content: flex-end;
}
.parcel-link__value {
  color: #282828;
  font-size: 16px;
  padding-right: 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1 auto;
  max-width: 100%;
  position: relative;
}
.parcel-link__value span {
  position: relative;
  z-index: 1;
}
.parcel-link__value span:after {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  height: 1px;
  z-index: -1;
  background: rgba(0,0,0,0.3);
}
</style>