<template>
  <div class="parcel-gallery-more">
    <div class="parcel-gallery-more__close" @click="onClose">
      <img src="@/assets/img/close-w.svg" />
    </div>

    <swiper :options="swiperOptionTop" class="gallery-top" ref="swiperTop">
      <swiper-slide v-for="(imgSrc, i) in imageList" :key="imgSrc + 'tpg' + i">
        <div class="swiper-zoom-container">
          <img :src="imgSrc" />
        </div>
      </swiper-slide>
    </swiper>

    <swiper
      :options="swiperOptionThumbs"
      class="gallery-thumbs"
      ref="swiperThumbs"
    >
      <swiper-slide
        v-for="(imgSrc, i) in imageList"
        :key="imgSrc + 'tpt' + i"
        :style="{
          backgroundImage: `url(${imgSrc})`,
        }"
      ></swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  name: "ParcelGalleryMore",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    imageList: Array,
    activeIndex: Number,
    onClose: Function,
  },
  data() {
    return {
      swiperOptionTop: {
        spaceBetween: 10,
        zoom: true,
        initialSlide: this.activeIndex
      },
      swiperOptionThumbs: {
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: "auto",
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        slideToClickedSlide: true,
        initialSlide: this.activeIndex
      },
    };
  },
  computed: {
    swiperTop() {
      return this.$refs.swiperTop.$swiper;
    },
    swiperThumbs() {
      return this.$refs.swiperThumbs.$swiper;
    },
  },
  mounted() {
    this.swiperTop.controller.control = this.swiperThumbs
    this.swiperThumbs.controller.control = this.swiperTop
  },
};
</script>

<style scoped>
.parcel-gallery-more {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 22;
  background: #000;
}
.swiper-container {
  background-color: #000;
}
.swiper-slide {
  background-size: cover;
  background-position: center;
}
.gallery-top {
  height: 90% !important;
  width: 100%;
}
.gallery-thumbs {
  height: 10% !important;
  box-sizing: border-box;
  padding: 10px 0;
}
.gallery-thumbs .swiper-slide {
  width: 17%;
  height: 100%;
  opacity: 0.4;
}
.gallery-thumbs .swiper-slide-active {
  opacity: 1;
}
.parcel-gallery-more__close {
  width: 40px;
  height: 40px;
  background: #000;
  border-radius: 50%;
  position: absolute;
  z-index: 3;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>