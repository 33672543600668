<template>
  <div class="parcel-check__list-item">
    <img v-if="!isSuccess" src="@/assets/img/parcel/check-gray.svg" />
    <img v-if="isSuccess" src="@/assets/img/parcel/check-green.svg" />
    <span v-html="name"></span>
    <b
      >${{ value }}
      <img
        v-if="isAbout"
        src="@/assets/img/parcel/star-black.svg"
        class="img-star"
    /></b>
  </div>
</template>

<script>
export default {
  name: "ParcelCheckItem",
  props: {
    name: String,
    value: Number,
    isSuccess: Boolean,
    isAbout: Boolean,
  },
};
</script>

<style scoped>
.parcel-check__list-item {
  position: relative;
  padding-left: 30px;
  padding-bottom: 4px;
  padding-top: 4px;
  display: flex;
  font-family: "Roboto Mono";
}
.parcel-check__list-item img {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.parcel-check__list-item span {
  color: #696969;
  font-size: 14px;
  flex: 1 auto;
}
.parcel-check__list-item b {
  text-align: right;
  color: #282828;
  font-size: 14px;
  font-weight: 500;
}
</style>
