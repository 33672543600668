// TODO: change all localRoutes <a> element to <div v-on:click="setRoute">
import { setAppState } from "./data-mutator"
import { appState } from "../store/appstate"

import i18n from "@/i18n"


// TODO: reInit lcl
export const ROUTES = {
    
}

class HistoryObj {
    name: string
    tabIdx: number
    constructor(name: string, tabIdx: number) {
        this.name = name
        this.tabIdx = tabIdx
    }
}

const history: HistoryObj[] = [] // routes history
history.push( new HistoryObj('home', 0) )

function initRouteMiddleware(routeName: string) {
    //@ts-ignore
    const k = window['Keyboard'];
    if(!k || !k.shrinkView) return false;
    if(routeName === 'finance') {
        k.shrinkView(false);
        k.disableScrollingInShrinkView(true);
    } else {
        k.shrinkView(true);
    }
}

export function setRoute(routeName: string, tabIdx = 0, routeParam: any = null): void {
    if (appState.platform === 'android' || appState.platform === 'iphone') initRouteMiddleware(routeName);
    if (routeName in ROUTES) {
        if (appState.currentView !== routeName) {
            setAppState('currentView', routeName)
            //@ts-ignore
            setAppState('currentViewName', i18n.t(ROUTES[routeName].titleKey))            
            history.push( new HistoryObj(routeName, tabIdx) )
        }

        setAppState('activeTabIdx', tabIdx || 0)
        setAppState('routeparam', routeParam || '')
        
        // set page scroll to top
        const w: any = window
        w.scrollTo(0,0)
    }
}

// reSet routeName if lang changed
export function resetRouteName(): void { 
    const routeName = appState.currentView
    //@ts-ignore
    setAppState('currentViewName', i18n.t(ROUTES[routeName].titleKey) )   
}

export function backHistory(e: any) {
    if (history.length === 1) { // exit form app if NO previous history
        const w: any = window
        w.navigator.app.exitApp()
        // TODO: show alert before exit
        return false 
    }

    const prevRouteObj = history[ history.length-2 ]

    setAppState('currentView', prevRouteObj.name)
    setAppState('activeTabIdx', prevRouteObj.tabIdx)
    
    // set page scroll to top
    const w: any = window
    w.scrollTo(0,0)

    history.pop() // remove last history route
    e.preventDefault()
}