<template>
  <transition key="parcel-volume-weight" name="fade">
    <div v-if="isOpened" class="parcel-check">
      <div class="parcel-check__overlay" @click="onClose"></div>
      <div class="parcel-check__wrapper">
        <div class="parcel-check__content">
          <div class="parcel-check__close" @click="onClose">
            <img src="@/assets/img/close-w.svg" />
          </div>
          <div class="parcel-check__title">
            {{ $t("cardVolumeWeightName") }}
          </div>
          <div class="parcel-check__list">
            <div class="parcel-check__list-item">
              <img src="@/assets/img/parcel/Weight.svg" />
              <span>{{ $t("cardFactWeight") }}</span>
              <b>{{ factWeight }} {{ $t("kg") }} </b>
            </div>
            <div class="parcel-check__list-item">
              <img src="@/assets/img/parcel/Line.svg" />
              <span>{{ $t("cardSizePackage") }}</span>
              <b>{{ packageSize }}</b>
            </div>
            <div class="parcel-check__list-item parcel-volume">
              <img src="@/assets/img/parcel/Cube.svg" />
              <span
                >{{ $t("cardVolumeWeight") }}
                <b>{{ $t("dwmf") }}x{{ volumeCoff }}</b></span
              >
              <b>{{ volumeWeight }} {{ $t("kg") }} </b>
            </div>
          </div>
          <div
            @click="isDetailBalloonOpened = true"
            class="parcel-check__total"
          >
            <span>{{ $t("cardVolumeWeightResult") }} </span>
            <b
              ><img src="@/assets/img/parcel/Info.svg" />{{ paymentWeight }}
              {{ $t("kg") }}</b
            >
          </div>
          <img src="@/assets/img/paper.svg" class="parcel-check__paper" />
        </div>
      </div>
      <balloon
        v-if="isDetailBalloonOpened"
        :text="$t('cardVolumeDescriptionBalloon')"
        v-bind:isopen.sync="isDetailBalloonOpened"
      ></balloon>
    </div>
  </transition>
</template>

<script>
import Balloon from "@/components/Balloon.vue";
import { mapGetters } from "vuex";

export default {
  components: { Balloon },
  name: "ParcelVolumeWeight",
  props: {
    isOpened: Boolean,
    factWeight: Number,
    paymentWeight: Number,
    volumeWeight: Number,
    width: Number,
    height: Number,
    length: Number,
  },
  data() {
    return {
      isDetailBalloonOpened: false,
    };
  },
  computed: {
    ...mapGetters("root", ["volumeCoff"]),
    packageSize() {
      if (!this.length) {
        return this.$t("noway");
      }
      return `${this.length}x${this.width}x${this.height} ${this.$t("cm")}`;
    },
  },
  methods: {
    onClose() {
      this.$emit("update:isOpened", false);
    },
  },
};
</script>

<style>
.parcel-check {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 600;
  box-sizing: border-box;
  padding-top: 50px;
  padding-bottom: 50px;
  background: rgb(40, 40, 40, 0.8);
}
.parcel-check__overlay {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
.parcel-check__wrapper {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
  max-height: 100%;
  overflow: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
}
.parcel-check__content {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 12px;
  padding-top: 32px;
  background: #ffffff;
  position: relative;
  max-width: 340px;
  margin-left: auto;
  margin-right: auto;
}
.parcel-check__close {
  background: #efb443;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.2);
  border-radius: 32px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  position: absolute;
  right: 16px;
  top: -21px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.parcel-check__close img {
  display: block;
}
.parcel-check__paper {
  position: absolute;
  left: 0;
  bottom: -30px;
  z-index: -1;
  width: 100%;
}
.parcel-check .img-star {
  display: inline-block;
  width: 6px;
  position: relative;
  top: -6px;
  margin-left: 1px;
  margin-right: 1px;
}
.parcel-check__title {
  font-size: 16px;
  color: #282828;
  font-family: "Helvetica Neue Medium";
  margin-bottom: 10px;
}
.parcel-check__total {
  border-top: 1px dashed #bfbfbf;
  padding-top: 8px;
  margin-top: 8px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  font-family: "Roboto Mono";
}
.parcel-check__total span {
  color: #696969;
  font-size: 14px;
  font-weight: 500;
}
.parcel-check__total b img {
  display: inline-block;
  margin-right: 4px;
  max-width: 20px;
}
.parcel-check__total b {
  text-align: right;
  color: #282828;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.parcel-check__result-item {
  display: flex;
  justify-content: space-between;
  font-family: "Roboto Mono";
}
.parcel-check__result-item span {
  color: #696969;
  font-size: 14px;
  font-style: italic;
}
.parcel-check__result-item b {
  text-align: right;
  color: #282828;
  font-size: 14px;
  font-weight: 500;
}
.parcel-check__helps {
  color: #bfbfbf;
  font-size: 12px;
  line-height: 1.3;
  margin-top: 16px;
}
.parcel-check__list-item {
  position: relative;
  padding-left: 30px;
  padding-bottom: 4px;
  padding-top: 4px;
  display: flex;
  font-family: "Roboto Mono";
}
.parcel-check__list-item img {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: 24px;
}
.parcel-check__list-item span {
  color: #696969;
  font-size: 14px;
  flex: 1 auto;
}
.parcel-check__list-item b {
  text-align: right;
  color: #282828;
  font-size: 14px;
  font-weight: 500;
}
.parcel-check__list-item span b {
  font-size: 11px;
  font-weight: 300;
  display: block;
  text-align: left;
  line-height: 14px;
}
.parcel-check__list-item.parcel-volume img {
  top: 8px;
  bottom: auto;
}
</style>
