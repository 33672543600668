






















import Vue from 'vue'

import { plusSVG } from '@/services/svg'


export default Vue.extend({
    props: {
        text: String,
        isopen: Boolean
    },
    data() {
        return { 
            plusSVG,
            isMounted: false
        }
    },
    mounted() {
        // asho fade anumation
        this.isMounted = true
    },

    methods: {
        closeByTimeout() {
            this.isMounted = false
            setTimeout(()=>{
                this.$emit('update:isopen', false)
            },450)
        }
    }
})
