<template>
  <div class="parcel-entity">
    <div v-if="hasBaseIconSlot" class="parcel-entity__icon">
      <slot name="base-icon"></slot>
    </div>
    <div class="parcel-entity__info" @click="onOpen">
      <div class="parcel-entity__label">
        {{ label }}
      </div>
      <div
        class="parcel-entity__value"
        :class="{ 'is-success': isSuccess, 'is-link': isLink, 'is-redlink': isRedLink }"
      >
        <div>{{ value }} <span v-if="additionalInfo">{{additionalInfo}}</span></div>
      </div>
    </div>
    <div class="parcel-entity__action" @click="handleAction">
      <slot name="action-icon"></slot>
    </div>

    <balloon v-if="isInfoOpened" :text="infoText" v-bind:isopen.sync="isInfoOpened"></balloon>
  </div>
</template>

<script>
import eventsRegister from '@/services/events-register';
import { copyToClipboard } from '@/utils/utils';

import Balloon from "@/components/Balloon.vue";

export default {
  name: "ParcelEntity",
  props: {
    label: String,
    value: String,
    additionalInfo: String,
    isLink: Boolean,
    isRedLink: Boolean,
    isSuccess: Boolean,
    infoText: String,
    type: String,
    customAction: {
      type: Function,
      default: null
    },
    onOpen: {
      type: Function,
      default: () => true
    }
  },
  components: {Balloon},
  data() {
    return {
      isInfoOpened: false,
    }
  },
  computed: {
    hasBaseIconSlot () {
      return Boolean(this.$slots['base-icon'])
    }
  },
  methods: {
    handleAction() {
      if (this.customAction) {
        return this.customAction()
      }
      if (this.type === 'copy') {
        this.onCopy();
      }
      if (this.type === 'info') {
        this.isInfoOpened = true;
      }
    },
    onCopy() {
      const clipBoard =
        window["cordova"] && window["cordova"].plugins.clipboard;

      const onSuccess = () => {
        this.$root.$emit(eventsRegister.addMessage, {
          type: "success",
          message: this.$t('copied'),
          autoClose: true,
        });
      };
      if (clipBoard) {
        window["cordova"].plugins.clipboard.copy(this.value, onSuccess);
      } else {
        copyToClipboard(this.value, onSuccess);
      }
    },
  },
};
</script>

<style>
.parcel-entity {
  display: flex;
  border-bottom: 1px solid #f1f1f1;
  min-height: 58px;
  box-sizing: border-box;
  align-items: center;
}
.parcel-entity__info {
  flex: 1 auto;
  padding-top: 6px;
  padding-bottom: 6px;
}
.parcel-entity__icon {
  min-width: 24px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 16px;
}
.parcel-entity__action {
  min-width: 40px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.parcel-entity__label {
  color: #696969;
  font-size: 12px;
  margin-bottom: 6px;
  line-height: 14px;
}
.parcel-entity__value {
  color: #282828;
  font-size: 16px;
  line-height: 18px;
}
.parcel-entity__value span {
  color: #ef8143;
}
.parcel-entity__value.is-success {
  color: #91c258;
}
.parcel-entity__value.is-link > div {
  position: relative;
  display: inline-block;
}
.parcel-entity__value.is-link > div:after {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2px;
  height: 1px;
  background: rgba(0,0,0,0.4);
}
.parcel-entity__value.is-redlink {
  color: #ef8143;
}
</style>