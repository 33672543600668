<template>
  <div
    class="parcel-product"
    @click.prevent="onEdit"
    :class="{ 'is-editable': isEditable }"
  >
    <div class="parcel-product__name">
      <span>{{ product.name }}</span>
      <div class="parcel-product__name-edit">
        <div
          v-if="isRemovable"
          class="parcel-product__remove"
          @click.stop="onRemove"
        >
          <img src="@/assets/img/i-close.svg" />
        </div>
        <img v-else-if="isEditable" src="@/assets/img/parcel/Edit.svg" />
      </div>
    </div>
    <div class="parcel-product__info">
      <div v-if="product.price" class="parcel-product__price">
        ${{ (product.price / product.count) | numberRound }}
      </div>
      <div v-if="product.count" class="parcel-product__count">
        {{ product.count }} {{ $t("pieces") }}
      </div>
      <div v-if="product.color" class="parcel-product__color">
        {{ product.color }}
      </div>
      <div v-if="product.size" class="parcel-product__size">
        {{ product.size }}
      </div>
      <div v-if="product.model" class="parcel-product__model">
        {{ product.model }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ParcelProductItem",
  props: {
    product: Object,
    onEdit: {
      type: Function,
      default: () => true,
    },
    onRemove: {
      type: Function,
      default: () => true,
    },
    isEditable: Boolean,
    isRemovable: Boolean,
  },
};
</script>

<style>
.parcel-product {
  padding-bottom: 8px;
  padding-top: 8px;
  border-bottom: 1px solid #f1f1f1;
}
.parcel-product:last-child {
  border-bottom: 1px solid transparent;
}
.is-editable .parcel-product__name span {
  /* border-bottom: 1px dotted #696969; */
  text-decoration: underline;
  text-decoration-style: dotted;
  text-underline-position: under;
}
.parcel-product__name {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-right: 60px;
  min-height: 25px;
}
.parcel-product__name span {
  color: #696969;
  font-family: "Helvetica Neue Medium";
  font-size: 16px;
  line-height: 1.4;
  padding-top: 3px;
  flex: 1 auto;
}
.parcel-product__name-edit {
  padding: 16px;
  position: absolute;
  right: -16px;
  top: -16px;
}
.parcel-product__info {
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px;
}
.parcel-product__info > div {
  color: #696969;
  padding-left: 16px;
  padding-right: 16px;
  border-left: 1px solid #f1f1f1;
  margin-top: 6px;
}
.parcel-product__info > div:first-child {
  border-left: none;
}
.parcel-product__remove {
  position: absolute;
  right: 6px;
  top: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.25;
}
</style>
