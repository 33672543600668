<template>
  <div class="parcel-payment">
    <div class="parcel-payment__price">
      <span>$</span>
      <b>{{ totalCost }}</b>
    </div>
    <div class="parcel-payment__label">{{ $t("cardPaymentNowText") }}</div>
    <div class="parcel-payment__helps">
      {{ helpText }}
    </div>
    <div
      class="parcel-payment__btn btnWideRound"
      :class="{
        disabled: isDisabled,
        isLoading: isLoading,
      }"
      @click="onPay"
    >
      {{ $t("goToPayPC") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ParcelPayment",
  props: {
    helpText: String,
    totalCost: String,
    isDisabled: Boolean,
    isLoading: Boolean,
    onPay: {
      type: Function,
      default: () => true,
    },
  },
};
</script>

<style>
.parcel-payment {
  padding-top: 22px;
  border-top: 1px solid #f1f1f1;
}
.parcel-payment__price {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.parcel-payment__price b {
  font-size: 24px;
  color: #282828;
  font-family: "Helvetica Neue Medium";
}
.parcel-payment__price span {
  font-size: 14px;
  color: #696969;
  position: relative;
  top: -8px;
  margin-left: 4px;
}
.parcel-payment__label {
  text-align: center;
  margin-bottom: 18px;
  margin-top: 4px;
  font-size: 12px;
  line-height: 1.4;
  color: #696969;
}
.parcel-payment__helps {
  color: #bfbfbf;
  font-size: 12px;
  text-align: center;
  max-width: 330px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  line-height: 1.4;
}
</style>
