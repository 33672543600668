<template>
  <div class="parcel-tax">
    <div class="parcel-tax__icon">🚥</div>
    <div class="parcel-tax__text">
      <span>{{ $t("cardTaxDescription") }}</span>
      <b>${{ count | numberRound }}</b>
    </div>
    <div class="parcel-tax__info" @click="isInfoOpened = true">
      <img src="@/assets/img/parcel/TaxInfo.svg" />
    </div>

    <balloon v-if="isInfoOpened" v-bind:isopen.sync="isInfoOpened">
      <p>{{ $t(`customsServiceInfoCalcP.${userCountryCode}`) }}:</p>
      <p>{{ $t(`customsServiceInfo2CalcP.${userCountryCode}`) }}</p>
      <p>{{ $t(`customsServiceInfo3CalcP.${userCountryCode}`) }}</p>
      <p>{{ $t(`customsServiceInfo4CalcP.${userCountryCode}`) }}</p>
    </balloon>
  </div>
</template>

<script>
import Balloon from "@/components/Balloon.vue";
import { mapGetters } from "vuex";
export default {
  name: "ParcelTax",
  components: { Balloon },
  props: {
    count: Number,
  },
  data() {
    return {
      isInfoOpened: false,
    };
  },
  computed: {
    ...mapGetters("user", ["userCountryCode"]),
  },
};
</script>

<style>
.parcel-tax {
  display: flex;
  background: rgba(239, 180, 67, 0.1);
  padding: 9px;
  align-items: center;
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 16px;
  padding-right: 16px;
}
.parcel-tax__icon {
  padding-right: 8px;
  padding-top: 3px;
}
.parcel-tax__text {
  flex: 1 auto;
  font-size: 12px;
  line-height: 1.3;
}
.parcel-tax__text span {
  color: #696969;
  margin-right: 6px;
}
.parcel-tax__info img {
  margin-bottom: 2px;
}
</style>
